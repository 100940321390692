import styled from 'styled-components';

export const SpinnerStyles = styled.div`
  &.fullScreen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #FF7300;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
