import styled from 'styled-components';

export const ButtonStyles = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  
  padding: 14px 20px;
  
  font-size: 18px;
  font-weight: 900;
  line-height: 22px;
  color: #000000;
  border: 2px solid #000;
  text-transform: uppercase;
  
  transition: .3s ease box-shadow, .3s ease border-color;
  
  @media (min-width: 768px) {
    padding: 10px 30px;
  }
  
  &:hover:not(.selected) {
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
  }

  &:active {
    box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.75);
  }
  
  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 34px;
  }

  &.selected {
    border: 2px solid #439000;
  }
`;
