import React from 'react';
import { CasesBaseStyles } from '../CasesBaseStyles';
import Banner from '../../../components/cases/banner/Banner';
import BackStage from '../../../components/cases/backStage/BackStage';

import whatWeDoImage from '../../../assets/images/Mykolaichuk/Group 205.png';
import decisionImage from '../../../assets/images/Mykolaichuk/Rectangle 411.png';
import { MykolaichukStyles } from './MykolaichukStyles';
import OtherCases from '../../../components/cases/otherCases/OtherCases';
import { useTranslation } from 'react-i18next';

function Mykolaichuk() {
  const { t } = useTranslation('case_mykolaichuk');

  return (
    <CasesBaseStyles>
      <MykolaichukStyles>
        <Banner
          title={t('banner_title')}
          videoUrl="https://youtu.be/Gwo2IT0iZx4"
          descriptionBlock={
            {
              title: t('banner_description_block_title'),
              platformName: 'Youtube',
              description: t('banner_description_block_description'),
              category: [t('banner_description_block_category')],
              link: 'https://youtu.be/Gwo2IT0iZx4',
              clients: [t('banner_description_block_client')],
            }
          }
        />

        <BackStage
          title={t('backstage_title')}
          image={whatWeDoImage}
          descriptionBlocks={
            [
              {
                description: t('backstage_description_block_1_description'),
                backgroundColor: '#000000',
              },
              {
                description: '',
                backgroundColor: '#000000',
              },
            ]
          }
        />

        <BackStage
          title={t('results_title')}
          titleColor="#FFF"
          titleWrapperBackgroundColor="#439000"
          image={decisionImage}
          extraClasses="decisionBackStage"
          descriptionBlocks={
            [
              {
                description: t('results_description_block_1_description'),
                backgroundColor: '#439000',
              },
              {
                description: t('results_description_block_2_description'),
                backgroundColor: '#000000',
              },
            ]
          }
        />
        <OtherCases />
      </MykolaichukStyles>
    </CasesBaseStyles>
  );
}

export default Mykolaichuk;
