import React, { useMemo } from 'react';
import { CasesStyles } from './CasesStyles';

import case1Image from '../../assets/images/cases/case-1.png';
import case2Image from '../../assets/images/cases/case-2.jpg';
import case3Image from '../../assets/images/cases/case-3.png';
import case4Image from '../../assets/images/cases/case-4.jpg';
import case5Image from '../../assets/images/cases/case-5.png';
import case7Image from '../../assets/images/cases/case-7.png';
import case8Image from '../../assets/images/cases/case-8.jpg';
import case9Image from '../../assets/images/cases/case-9.jpg';
import case10Image from '../../assets/images/cases/case-10.png';
import case11Image from '../../assets/images/cases/case-11.png';
import case12Image from '../../assets/images/cases/case-12.png';
import case13Image from '../../assets/images/cases/case-13.png';
import case14Image from '../../assets/images/cases/case-14.png';
import case15Image from '../../assets/images/cases/case-15.png';
import case16Image from '../../assets/images/cases/case-16.jpg';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ShowTextByLetter from '../../utils/ShowTextByLetter/ShowTextByLetter';
import { InView } from 'react-intersection-observer';

export type TCases = {title: string, subtitle?: string, path: string, img: string, listData?: {listTitle?: string, listItems: string[]}}[]

export const images = [
  case1Image,
  case2Image,
  case3Image,
  case4Image,
  case5Image,
  'https://optim.tildacdn.com/tild3866-6166-4235-b536-306631623461/-/resize/480x480/-/format/webp/IMG_5506.JPG',
  case7Image,
  case8Image,
  case9Image,
  case10Image,
  case11Image,
  case12Image,
  case13Image,
  case14Image,
  case15Image,
  case16Image,
];

function Cases() {
  const { i18n, t } = useTranslation('works');

  const cases: TCases = useMemo(() => {
    const casesFromJson = t('list', { returnObjects: true });

    return casesFromJson.map((caseItem, index) => ({ ...caseItem, img: images[index] || '' })).reverse();
  }, [i18n.language]);

  return (
    <CasesStyles>
      <div className="container">
        <h2 className="h2">{ShowTextByLetter({ text: t('title'), delay: 80 })}</h2>

        <ul className="casesList">
          {cases.map((caseItem) => (
            <InView as="li" onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)} className="casesListItem" key={caseItem.title}>
              <Link to={`/${i18n.language}/cases/${caseItem.path}`}>
                <img src={caseItem.img} loading="lazy" alt={caseItem.title} />

                <div className="textBlock">
                  <div className="titleWrapper">
                    <h4>{caseItem.title}</h4>

                    {caseItem.subtitle && <span className="subtitle">{caseItem.subtitle}</span>}
                  </div>

                  {caseItem.listData && (
                    <div className="innerListWrapper">
                      {caseItem.listData.listTitle && <span className="innerListTitle">{caseItem.listData.listTitle}</span> }

                      <ul>
                        {caseItem.listData.listItems.map((innerListItemText) => <li className="innerListItem" key={innerListItemText}>{innerListItemText}</li>)}
                      </ul>
                    </div>
                  )}
                </div>
              </Link>
            </InView>
          ))}
        </ul>
      </div>
    </CasesStyles>
  );
}

export default Cases;
