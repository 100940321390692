import styled from 'styled-components';

export const ClientsStyles = styled.div`
  padding: 80px 0;
  
  @media (min-width: 1024px) {
    padding: 100px 0;
  }
  
  .head {
    display: flex;
    flex-direction: column;
    
    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      
      margin-bottom: 60px;
    }
    
    .slogan {
      display: none;
      
      @media (min-width: 1024px) {
        display: inline-flex;
      }
    }
  }
  
  .slogan, img {
    opacity: 0;
    
    transition: 1s ease opacity;
    
    &.inView {
      opacity: 1;
    }
  }
  
  .slogan {
    font-size: 20px;
    font-weight: 900;
    line-height: 25px;
    
    @media (min-width: 1024px) {
      font-size: 39px;
      line-height: 39px;
    }
  }
  
  .moreText {
    font-size: 18px;
    font-weight: 900;
    line-height: 22px;
    text-transform: uppercase;
    
    &.desktopMoreText {
      display: none;
      
      @media (min-width: 1024px) {
        display: inline-flex;
      }
    }   
    
    &.mobileMoreText {
      @media (min-width: 1024px) {
        display: none;
      }
    }
  }
  
  .listBlockWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    
    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      
      margin-bottom: 80px;
    }
    
    .listWrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      
      .moreText {
        @media (min-width: 1024px) {
          display: none;
        }
      } 
    }
    
    .imageWrapper {
      img {
        width: 100%;
        
        margin-bottom: 30px;
        
        @media (min-width: 1024px) {
          margin-bottom: 0;
        }
      }
      
      .slogan {
        @media (min-width: 1024px) {
          display: none;
        }
      }
    }
    
    ul {
      li {
        position: relative;

        font-size: 29px;
        font-weight: 900;
        line-height: 39px;
        text-transform: uppercase;

        @media (min-width: 1024px) {
          font-size: 39px;
          line-height: 39px;
        }

        &:not(:last-child) {
          margin-bottom: 12px;
        }
        
        &.inView {
          &::after {
            width: calc(100% + 40px);
          }
        }
        
        &::after {
          content: "";
          position: absolute;
          width: 0;
          left: -20px;
          bottom: -4px;
          height: 1px;
          background: #00000033;
          
          transition: 1s ease width;
          
          @media (min-width: 1024px) {
            left: -40px;
          }
        }
      }
    }
  }
`;
