import React, { useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

interface ShowTextByLetterProps {
  text: string;
  delay?: number;
  infinite?: boolean;
  callback?: () => void;
  deleteTextByLetterBeforeCallback?: boolean;
}

const ShowTextByLetter: React.FC<ShowTextByLetterProps> = ({
  text,
  delay = 3,
  infinite = false,
  callback,
  deleteTextByLetterBeforeCallback = false,
}) => {
  const { i18n } = useTranslation();

  const [currentText, setCurrentText] = useState<string>(text[0]);
  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const [backWardAnimationActive, setBackwardAnimationActive] = useState<boolean>(false);
  const [animationStart, setAnimationStart] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  useEffect(() => {
    let addTextTimeout: any;

    if (animationStart) {
      if (!backWardAnimationActive) {
        if (currentIndex < text.length) {
          addTextTimeout = setTimeout(() => {
            setCurrentText((prevText) => prevText + text[currentIndex]);
            setCurrentIndex((prevIndex) => prevIndex + 1);
          }, delay);
        } else if (callback && !deleteTextByLetterBeforeCallback) {
          callback();
        } else if (deleteTextByLetterBeforeCallback) {
          if (addTextTimeout) {
            clearTimeout(addTextTimeout);
          }

          setTimeout(() => {
            setBackwardAnimationActive(true);
          }, 400);
        } else if (infinite) {
          setCurrentIndex(0);
          setCurrentText('');
        }
      }
    }

    return () => {
      clearTimeout(addTextTimeout);
    };
  }, [text, currentText, animationStart]);

  useEffect(() => {
    let deleteTextTimeout: any;

    if (backWardAnimationActive) {
      if (deleteTextByLetterBeforeCallback && currentText.length > 0 && callback) {
        deleteTextTimeout = setTimeout(() => {
          setCurrentText((prevText) => prevText.substring(0, prevText.length - 1));
          setCurrentIndex((prevIndex) => prevIndex - 1);
        }, delay);
      } else if (callback) {
        if (deleteTextTimeout) {
          clearTimeout(deleteTextTimeout);
        }

        setBackwardAnimationActive(false);

        callback();
      }
    }

    return () => {
      clearTimeout(deleteTextTimeout);
    };
  }, [backWardAnimationActive, currentText]);

  useEffect(() => {
    if (currentLanguage !== i18n.language) {
      setCurrentLanguage(i18n.language);
      setCurrentText(text[0]);
      setCurrentIndex(1);

      if (animationStart) {
        setAnimationStart(false);
        setTimeout(() => {
          setAnimationStart(true);
        }, 100);
      }
    }
  }, [i18n.language]);

  return <InView as="span" onChange={(inView, entry) => (inView ? setAnimationStart(true) : null)} className="textByLetter">{currentText}</InView>;
};

export default ShowTextByLetter;
