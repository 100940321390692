import React, { Fragment, ReactNode, useMemo } from 'react';
import { BackStageStyles } from './BackStageStyles';
import ReactPlayer from 'react-player/lazy';
import { useWindowSize } from 'usehooks-ts';
import showTextByLetter from '../../../utils/ShowTextByLetter/ShowTextByLetter';
import { InView } from 'react-intersection-observer';
import classNames from 'classnames';

interface BackStageProps {
  title: string;
  titleWrapperBackgroundColor?: string;
  titleColor?: string;
  videoUrl?: string;
  image?: string;
  children?: ReactNode;
  extraClasses?: string;
  oneDescriotionBlock?: boolean;

  descriptionBlocks: {
    title?: string;
    description?: string;
    children?: ReactNode;
    withPaddingBottom?: boolean;
    backgroundColor: '#439000' | '#000000';
  }[];
}

function BackStage({
  titleWrapperBackgroundColor = '#000', titleColor = '#FFF', extraClasses, title, children, image, videoUrl, descriptionBlocks, oneDescriotionBlock,
}: BackStageProps) {
  const { width } = useWindowSize();

  const mediaElement = useMemo(() => {
    if (image) {
      return <img className="mediaImage" src={image} alt={title} />;
    }

    if (videoUrl) {
      return (
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={videoUrl}
            width="100%"
            height="100%"
            controls
          />
        </div>
      );
    }

    if (children) {
      return children;
    }

    return null;
  }, [videoUrl, image]);

  return (
    <BackStageStyles className={extraClasses}>
      <div className="titleWrapper" style={{ backgroundColor: titleWrapperBackgroundColor }}>
        <div className="container">
          <h2 className="h2" style={{ color: titleColor }}>{showTextByLetter({ text: title, delay: 40 })}</h2>
        </div>
      </div>

      <div className="mediaWrapperBackgroundContainer" style={{ backgroundColor: titleWrapperBackgroundColor }}>
        <div className="mediaWrapper">
          {mediaElement}
        </div>
      </div>

      <div style={oneDescriotionBlock && descriptionBlocks.length === 1 ? { backgroundColor: descriptionBlocks[0].backgroundColor } : {}} className="descriptionBlocksWrapper">
        <div className={classNames('descriptionBlocks', { oneElement: oneDescriotionBlock && descriptionBlocks.length === 1 })}>
          {descriptionBlocks.map((blockInfo) => (
            <InView onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)} key={blockInfo.description} style={width < 768 ? { backgroundColor: blockInfo.backgroundColor } : {}} className="descriptionBlock">
              {blockInfo.title && <span className="descriptionBlockTitle">{blockInfo.title}</span>}
              {blockInfo.withPaddingBottom && <div className="emptyBlock" />}

              {blockInfo.description && <p className="descriptionBlockDescription">{blockInfo.description}</p>}
              {blockInfo.children && blockInfo.children}
            </InView>
          ))}
        </div>

        {descriptionBlocks.map((blockInfo, index) => (
          <div key={blockInfo.description} className={`backgroundContainer backgroundContainer-${index + 1}`} style={{ backgroundColor: blockInfo.backgroundColor }} />
        ))}
      </div>

    </BackStageStyles>
  );
}

export default BackStage;
