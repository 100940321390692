import React from 'react';
import { CasesBaseStyles } from '../CasesBaseStyles';
import Banner from '../../../components/cases/banner/Banner';
import BackStage from '../../../components/cases/backStage/BackStage';

import resultImage from '../../../assets/images/jerryHeilZdn/Jerry-Heil-zdn-results.png';
import OtherCases from '../../../components/cases/otherCases/OtherCases';
import { useTranslation } from 'react-i18next';

function JerryHeilZdn() {
  const { t } = useTranslation('case_jerry_heil_zdn');

  return (
    <CasesBaseStyles>
      <Banner
        title={t('banner_title')}
        videoUrl="https://youtu.be/GgIzbZTnv6c"
        descriptionBlock={
          {
            title: t('banner_description_block_title'),
            platformName: 'Youtube',
            description: t('banner_description_block_description'),
            category: [t('banner_description_block_category')],
            link: 'https://youtu.be/GgIzbZTnv6c',
            clients: [t('banner_description_block_client')],
          }
        }
      />

      <BackStage
        title={t('backstage_title')}
        videoUrl="https://youtu.be/lFGNAzdv_OM"
        descriptionBlocks={
          [
            {
              description: t('backstage_description_block_1_description'),
              title: t('backstage_description_block_1_title'),
              backgroundColor: '#000000',
            },
            {
              description: t('backstage_description_block_2_description'),
              title: t('backstage_description_block_2_title'),
              backgroundColor: '#439000',
            },
          ]
        }
      />

      <BackStage
        title={t('results_title')}
        titleColor="#000"
        titleWrapperBackgroundColor="#FFF"
        image={resultImage}
        descriptionBlocks={
          [
            {
              description: t('results_description_block_1_description'),
              title: t('results_description_block_1_title'),
              backgroundColor: '#000000',
            },
            {
              description: t('results_description_block_2_description'),
              title: t('results_description_block_2_title'),
              backgroundColor: '#439000',
            },
          ]
        }
      />
      <OtherCases />
    </CasesBaseStyles>
  );
}

export default JerryHeilZdn;
