import React, { ReactNode, useMemo } from 'react';
import ReactPlayer from 'react-player/lazy';
import { BannerStyles } from './BannerStyles';
import Circle from '../../other/circle/Circle';
import showTextByLetter from '../../../utils/ShowTextByLetter/ShowTextByLetter';
import { InView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  children?: ReactNode;
  videoUrl?: string;
  image?: string;
  descriptionBlock: {
    title: string;
    description: string;
    clients: string[];
    category: string[];
    platformName?: string;
    link?: string;
  }
}

function Banner({
  descriptionBlock, title, image, children, videoUrl,
}: Props) {
  const { t } = useTranslation('cases_banner');

  const bannerMediaComponent = useMemo(() => {
    if (image) {
      return <img className="bannerImage" src={image} alt={title} />;
    }

    if (videoUrl) {
      return (
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={videoUrl}
            width="100%"
            height="100%"
            controls
          />
        </div>
      );
    }

    if (children) {
      return children;
    }

    return null;
  }, [videoUrl, image, children]);

  return (
    <BannerStyles className="banner">
      <div className="container">
        <h1>
          {showTextByLetter({ text: title, delay: 40 })}
        </h1>
      </div>

      <div className="mediaWrapper">
        {bannerMediaComponent}
      </div>

      <div className="container">
        <div className="textWrapper">
          <InView as="h2" onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)}>{descriptionBlock.title}</InView>

          <InView as="div" onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)} className="rightSide">
            <h3>
              {descriptionBlock.description}
            </h3>

            <div className="clientInfoBlocksWrapper">
              {descriptionBlock.clients.length > 0 && (
                <div className="clientInfoBlock">
                  <span className="clientInfoBlockTitle">{t('client')}</span>

                  <ul>
                    {descriptionBlock.clients.map((client) => <li key={client}>{client}</li>)}
                  </ul>
                </div>
              )}

              <div className="clientInfoBlock">
                <span className="clientInfoBlockTitle">{t('category')}</span>

                <ul>
                  {descriptionBlock.category.map((category) => <li key={category}>{category}</li>)}
                </ul>
              </div>
            </div>

            {descriptionBlock.link && descriptionBlock.platformName && (
              <a href={descriptionBlock.link} rel="noreferrer" target="_blank">
                <Circle />

                {t('watch_on')}
                {' '}
                {descriptionBlock.platformName}
              </a>
            )}
          </InView>
        </div>
      </div>
    </BannerStyles>
  );
}

export default Banner;
