import Button from '../../../../components/formPage/button/Button';
import crounIcon from '../../../../assets/icons/form/croun.png';
import React, { useMemo } from 'react';
import { Values } from '../../Form';
import { useTranslation } from 'react-i18next';

export const Step4 = ({
  setFieldValue, value, handleClickToNextButton, handleClickToBackButton,
}: {
  setFieldValue: (field: keyof Values, value: string) => void,
  value: string,
  handleClickToNextButton: () => void
  handleClickToBackButton: () => void
}) => {
  const { t, i18n } = useTranslation('form');

  const budgetOptions = useMemo(() => t('step_4_options', { returnObjects: true }), [i18n.language]);

  return (
    <div className="step">
      <div className="stepHead">
        <h2>{t('step_4_title')}</h2>

        <div className="optionsButtonsWrapper">
          {budgetOptions.map((option, index) => (
            <Button text={option.text} selected={value === option.value} onClick={() => setFieldValue('budget', option.value)} icon={index === budgetOptions.length - 1 ? crounIcon : undefined} />
          ))}
        </div>
      </div>

      <div className="stepFooter">
        <button type="button" className="backButton" onClick={handleClickToBackButton}>{t('back')}</button>

        <button disabled={value === ''} type="button" className="nextButton text90" onClick={handleClickToNextButton}>
          {t('next')}
        </button>
      </div>
    </div>
  );
};
