import React from 'react';
import Smile from '../../../assets/icons/smile/Smile';

import image from '../../../assets/images/main/valeriya-fadeeva.jpg';
import { AboutUsStyles } from './AboutUsStyles';
import { InView } from 'react-intersection-observer';
import showTextByLetter from '../../../utils/ShowTextByLetter/ShowTextByLetter';
import { useTranslation } from 'react-i18next';

function AboutUs() {
  const { t } = useTranslation('main');

  return (
    <AboutUsStyles>
      <div className="container">
        <div className="leftPart">
          <div className="content">
            <h2 className="h2">
              {showTextByLetter({ text: t('about_us_title'), delay: 60 })}
            </h2>

            <Smile />
          </div>

        </div>

        <div className="rightPart">
          <div className="content">
            <img src={image} alt="Valeria Fadyeyeva" loading="lazy" />

            <InView onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)} className="textContainer">
              <div className="head">
                <h3>{t('about_us_right_part_title')}</h3>

                <p>
                  {t('about_us_description')}
                </p>
              </div>

              <div className="signatureContainer">
                <span className="name">{t('about_us_name')}</span>

                <span className="position">{t('about_us_position')}</span>
              </div>
            </InView>
          </div>
        </div>
      </div>
    </AboutUsStyles>
  );
}

export default AboutUs;
