import styled from 'styled-components';

export const CasesStyles = styled.div`
  padding: 40px 0;
  
  @media (min-width: 1024px) {
    padding: 60px 0;
  }
  
  .casesList {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    
    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
    
    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr 1fr;

      grid-auto-rows: 400px;
    }
    
    .casesListItem {
      display: flex;
      
      border-radius: 8px;
      overflow: hidden;
      
      opacity: 0;
      transform: translateY(30px);
      transition: 1s ease opacity, 0.3s ease transform;
      
      &.inView {
        opacity: 1;
        transform: translateY(0);
      }
      
      &:hover {
        .textBlock {
          @media (min-width: 1024px) {
            opacity: 1;
          }
        }
      }
      
      a {
        position: relative;
        
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        .textBlock {
          background: #000;
          color: #FFF;
          font-weight: 900;

          padding: 20px;
          box-sizing: border-box;

          display: flex;
          align-items: center;
          text-align: center;
          flex-direction: column;
          gap: 30px;
          
          @media (min-width: 1024px) {
            position: absolute;
            width: 100%;
            height: 100%;
            
            align-items: center;
            justify-content: center;
            
            opacity: 0;
            transition: .3s ease opacity;

            background-color: rgba(0, 0, 0, 0.6);
          }

          span {
            color: #FFF;
          }

          h4 {
            color: #FF7300;

            font-size: 28px;
            line-height: 28px;
            
            margin-bottom: 6px;
          }
          
          .subtitle, .innerListTitle {
            font-size: 18px;
            line-height: 18px;
          }

          .innerListWrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;

            ul {
              display: flex;
              flex-direction: column;
              gap: 4px;
              
              li {
                font-size: 14px;
                line-height: 14px;
              }
            }
          }
        }
      }
    }
  }
`;
