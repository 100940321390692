import React from 'react';
import { CasesBaseStyles } from '../CasesBaseStyles';
import Banner from '../../../components/cases/banner/Banner';
import BackStage from '../../../components/cases/backStage/BackStage';

import backstageImageDesktop from '../../../assets/images/coffee/backstage-desktop.png';
import backstageImageMobile from '../../../assets/images/coffee/backstage-mobile.png';
import { CoffeeStyles } from './CoffeeStyles';
import Results from '../../../components/cases/ufnpa3/results/Results';
import OtherCases from '../../../components/cases/otherCases/OtherCases';
import { useTranslation } from 'react-i18next';

import resultImage from '../../../assets/images/coffee/results-1.jpg';
import { useWindowSize } from 'usehooks-ts';

function Coffee() {
  const { width } = useWindowSize();
  const { t } = useTranslation('case_coffee');

  return (
    <CasesBaseStyles>
      <CoffeeStyles>
        <Banner
          title={t('banner_title')}
          videoUrl="https://youtu.be/9Q7kfx5HS2c?si=9v8QBDTJH8kcST3l"
          descriptionBlock={
            {
              title: t('banner_description_block_title'),
              platformName: 'YouTube',
              description: t('banner_description_block_description'),
              category: [t('banner_description_block_category')],
              link: 'https://youtu.be/9Q7kfx5HS2c?si=9v8QBDTJH8kcST3l',
              clients: [t('banner_description_block_client_1')],
            }
          }
        />

        <BackStage
          title={t('backstage_title')}
          image={width >= 768 ? backstageImageDesktop : backstageImageMobile}
          descriptionBlocks={
            [
              {
                description: t('backstage_description_block_1_description'),
                title: t('backstage_description_block_1_title'),
                backgroundColor: '#000000',
              },
              {
                description: t('backstage_description_block_2_description'),
                title: t('backstage_description_block_2_title'),
                backgroundColor: '#439000',
              },
            ]
          }
        />

        <BackStage
          title={t('results_title')}
          titleColor="#000"
          titleWrapperBackgroundColor="#FFF"
          extraClasses="results"
          image={resultImage}
          descriptionBlocks={
            [
              {
                description: t('results_description_block_1_description'),
                title: t('results_description_block_1_title'),
                backgroundColor: '#000000',
              },
              {
                description: t('results_description_block_2_description'),
                title: t('results_description_block_2_title'),
                backgroundColor: '#439000',
              },
            ]
          }
        />

        <OtherCases />
      </CoffeeStyles>
    </CasesBaseStyles>
  );
}

export default Coffee;
