import styled from 'styled-components';

export const LayoutStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  
  overflow: clip;
  
  main {
    flex-grow: 1;
  }
`;
