import React from 'react';
import Marquee from 'react-fast-marquee';
import Smile from '../../../assets/icons/smile/Smile';

import './styles.css';
import { useTranslation } from 'react-i18next';

interface Props {
  background: '#439000' | '#FF7300';
}

function MarqueeLine({ background }: Props) {
  const { t } = useTranslation('main');

  return (
    <Marquee className="marqueeLine" autoFill style={{ background }}>
      <div className="marqueeItem">
        <span className="marqueeText">
          {t('marquee_line')}
        </span>

        <Smile />
      </div>
    </Marquee>
  );
}

export default MarqueeLine;
