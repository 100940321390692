import React, { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../header/Header';
import { LayoutStyles } from './LayoutStyles';
import Footer from '../footer/Footer';
import GoToFormSection from '../goToFormSection/GoToFormSection';

function Layout() {
  const { pathname } = useLocation();

  const isMainPage = useMemo(() => pathname.split('/').length === 2, [pathname]);

  return (
    <LayoutStyles>
      <Header />
      <main>
        <Outlet />
      </main>
      {!isMainPage && <GoToFormSection />}
      <Footer />
    </LayoutStyles>
  );
}

export default Layout;
