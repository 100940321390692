import React from 'react';
import { CasesBaseStyles } from '../CasesBaseStyles';
import Banner from '../../../components/cases/banner/Banner';
import BackStage from '../../../components/cases/backStage/BackStage';

import { UNFPA4Styles } from './UNFPA4Styles';
import Results from '../../../components/cases/ufnpa3/results/Results';
import OtherCases from '../../../components/cases/otherCases/OtherCases';
import { useTranslation } from 'react-i18next';

import resultImage from '../../../assets/images/ufnap4/result-baner.jpg';
import backstageImage1 from '../../../assets/images/ufnap4/backstage-1.png';
import backstageImage2 from '../../../assets/images/ufnap4/backstage-2.png';
import bannerImage from '../../../assets/images/ufnap4/banner.jpg';

function UNFPA4() {
  const { t } = useTranslation('case_unfpa_4');

  return (
    <CasesBaseStyles>
      <UNFPA4Styles>
        <Banner
          title={t('banner_title')}
          image={bannerImage}
          descriptionBlock={
            {
              title: t('banner_description_block_title'),
              platformName: 'Youtube',
              description: t('banner_description_block_description'),
              category: [t('banner_description_block_category')],
              link: 'https://www.tiktok.com/@okay_notokay',
              clients: [t('banner_description_block_client_1')],
            }
          }
        />

        <BackStage
          title={t('backstage_title')}
          extraClasses="backstage"
          descriptionBlocks={
            [
              {
                description: t('backstage_description_block_1_description'),
                title: t('backstage_description_block_1_title'),
                backgroundColor: '#000000',
              },
              {
                description: t('backstage_description_block_2_description'),
                title: t('backstage_description_block_2_title'),
                backgroundColor: '#439000',
              },
            ]
          }
        >
          <img src={backstageImage1} alt="" />
          <img src={backstageImage2} alt="" />
        </BackStage>

        <BackStage
          title={t('results_title')}
          titleColor="#000"
          titleWrapperBackgroundColor="#FFF"
          image={resultImage}
          descriptionBlocks={
            [
              {
                description: t('results_description_block_1_description'),
                title: t('results_description_block_1_title'),
                backgroundColor: '#000000',
              },
              {
                description: t('results_description_block_2_description'),
                title: t('results_description_block_2_title'),
                backgroundColor: '#439000',
              },
            ]
          }
        />

        <OtherCases />
      </UNFPA4Styles>
    </CasesBaseStyles>
  );
}

export default UNFPA4;
