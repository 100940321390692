import styled from 'styled-components';

export const AboutUsStyles = styled.div`
  color: #FFF;
  
  @media (min-width: 1024px) {
    position: relative;
    
    &::after {
      content: "";
      position: absolute;
      width: 50dvw;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #439000;
    }
    
    &::before {
      content: "";
      position: absolute;
      width: 50dvw;
      height: 100%;
      top: 0;
      right: 0;
      background-color: #000;
    }
  }
  
  .container {
    display: flex;
    flex-direction: column;
    
    @media (min-width: 1024px) {
      flex-direction: row;
      
      >div {
        flex: 1 1 0;
      }
    }
    
    .rightPart {
      position: relative;
      
      &::after {
        content: "";
        position: absolute;
        width: calc(100% + 40px);
        height: 100%;
        left: -20px;
        top: 0;
        
        background-color: #000;

        @media (min-width: 1024px) {
          display: none;
        }
      }
      
      .content {
        position: relative;
        z-index: 1;
        
        .textContainer {
          padding-bottom: 30px;
          
          opacity: 0;
          transform: translateY(20px);
          
          transition: 1s ease opacity, 0.3s ease transform;
          
          &.inView {
            transform: translateY(0);
            opacity: 1;
          }
        }
        
        @media (min-width: 1024px) {
          .textContainer {
            padding-left: 40px;
            padding-bottom: 40px;
          }
        }
        
        img {
          width: 100dvw;

          transform: translateX(-20px);
          
          @media (min-width: 1024px) {
            width: calc(100% + 20px);
            transform: translateX(10px);
          }

          @media (min-width: 1300px) {
            width: 100%;
            transform: translateX(8px);
          }
        }
        
        .head {
          display: flex;
          flex-direction: column;
          gap: 24px;
          
          padding: 30px 0 0;
          
          @media (min-width: 1024px) {
            gap: 30px;
            
            padding: 70px 0 30px;
          }
          
          p {
            white-space: pre-wrap;
            font-size: 18px;
            letter-spacing: 0.6px;
          }
        }
        
        .signatureContainer {
          display: flex;
          flex-direction: column;
          gap: 6px;
          
          span {
            color: #FFF;
            text-transform: uppercase;
            font-weight: 900;
          }
          
          .name {
            font-size: 18px;
            line-height: 22px;
          }
          
          .position {
            font-size: 13px;
            line-height: 16px;
            opacity: 0.5;
          }
        }
      }
    }
    
    .leftPart {
      position: relative;
      display: flex;
      
      padding-top: 80px;
      
      .content {
        flex-grow: 1;
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      
      .smileIcon {
        display: none;
        
        @media (min-width: 1024px) {
          display: block;
          
          width: 126px;
          height: 126px;
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: calc(100% + 40px);
        height: 100%;
        left: -20px;
        top: 0;

        background-color: #439000;
        
        @media (min-width: 1024px) {
          display: none;
        }
      }

      .content {
        position: relative;
        z-index: 1;
      }
    }
  }
`;
