import styled from 'styled-components';

export const Divergent2Styles = styled.div`
  .banner {
  }
  
  .results {
    .descriptionBlockDescription {
      max-width: 820px;
    }
  }
`;
