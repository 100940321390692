import styled from 'styled-components';

export const BackStageStyles = styled.div`
  .titleWrapper {
    padding: 30px 0 16px 0;

    @media (min-width: 768px) {
      padding: 70px 0 16px 0;
    }
  }

  .mediaWrapper {
    max-width: 1440px;
    margin: auto;

    .player-wrapper {
      height: auto;
      width: auto;

      .react-player {
        padding-top: 56.25%;
        position: relative;
      }

      .react-player > div {
        top: 0;

        position: absolute;
      }
    }

    .mediaImage {
      width: 100%;
      height: auto;
      
      transform: translateY(3px);
    }
  }

  .descriptionBlocksWrapper {
    position: relative;

    .descriptionBlocks {
      position: relative;
      z-index: 1;

      display: grid;
      grid-template-columns: 1fr;
      
      max-width: 1360px;
      width: 100%;
      margin: auto;
      
      &:not(&.oneElement) {
        @media (min-width: 768px) {
          grid-template-columns: 1fr 1fr;
        }
      }

      .descriptionBlock {
        display: flex;
        flex-direction: column;
        gap: 30px;

        padding: 30px 20px;
        
        box-sizing: border-box;

        @media (min-width: 768px) {
          padding: 60px 20px;
        }

        &:nth-child(1) {
          @media (min-width: 1300px) {
            padding-right: 60px;
            padding-left: 0;
          }
        }

        &:nth-child(2) {
          @media (min-width: 1300px) {
            padding-left: 60px;
            padding-right: 0;
          }
        }

        &:nth-child(1) {
          @media (min-width: 768px) {
            padding-right: 60px;
          }
        }

        &:nth-child(2) {
          @media (min-width: 768px) {
            padding-left: 60px;
          }
        }
        
        * {
          opacity: 0;
          transform: translateY(20px);
          
          transition: 1s ease opacity, .3s ease transform;
        }

        &.inView {
          * {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .emptyBlock {
          display: none;
          
          @media (min-width: 768px) {
            display: block;
            
            height: 39px;
          }
        }

        .descriptionBlockTitle {
          font-size: 25px;
          font-weight: 900;
          line-height: 25px;
          letter-spacing: 0;

          @media (min-width: 768px) {
            font-size: 39px;
            line-height: 39px;
          }
        }

        .descriptionBlockDescription {
          font-size: 14px;
          font-weight: 900;
          line-height: 18px;
          letter-spacing: 0;
          color: #FFF;
          
          white-space: pre-wrap;

          @media (min-width: 768px) {
            font-size: 18px;
            line-height: 22px;
          }
        }
        
        a {
          color: #FFF;
          text-decoration: underline;
        }

        span, p {
          color: #FFF;
          text-transform: uppercase;
        }
      }
    }

    .backgroundContainer {
      display: none;
      
      position: absolute;
      top: 0;

      width: 50dvw;
      height: 100%;

      @media (min-width: 768px) {
        display: block;
      }

      &.backgroundContainer-1 {
        left: 0;
      }

      &.backgroundContainer-2 {
        right: 0;
      }
    }
  }
`;
