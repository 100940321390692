import styled from 'styled-components';

export const UNFPA3Styles = styled.div`
  .banner {
    .mediaWrapper {
      position: relative;

      img {
        position: relative;
        z-index: 1;
      }

      &::after {
        content: "";
        position: absolute;
        top: -15px;
        left: 0;

        transform: translateX(-33%);

        width: 200dvw;
        height: 100%;

        background-color: #000;

        @media (min-width: 768px) {
          top: -30px;
        }
      }
    }
  }
`;
