import React, { useEffect, useRef } from 'react';
import { SpinnerStyles } from './SpinnerStyles';
import classNames from 'classnames';

interface SpinnerProps {
  size?: number;
  fullScreen?: boolean;
}

function Spinner({ size, fullScreen }: SpinnerProps) {
  const spinnerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (size && spinnerRef && !!spinnerRef.current) {
      spinnerRef.current.style.width = `${size}px`;
      spinnerRef.current.style.height = `${size}px`;
    }
  }, [size, spinnerRef]);

  return (
    <SpinnerStyles className={classNames('spinnerContainer', { fullScreen })}>
      <div className="spinner" ref={spinnerRef} />
    </SpinnerStyles>
  );
}

export default Spinner;
