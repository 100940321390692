export default (
  <svg xmlns="http://www.w3.org/2000/svg" width="1360" height="130" viewBox="0 0 1360 130" fill="none">
    <path d="M0 2.46279H47.7441C57.2929 2.46279 64.7198 4.22192 70.0247 7.7402C75.3296 11.1412 79.2788 15.7736 81.8723 21.6374C84.5837 27.3839 86.2341 34.0686 86.8236 41.6915C87.413 49.1971 87.7077 57.0546 87.7077 65.2639C87.7077 73.4732 87.413 81.3893 86.8236 89.0122C86.2341 96.5178 84.5837 103.203 81.8723 109.066C79.2788 114.813 75.3296 119.445 70.0247 122.963C64.7198 126.364 57.2929 128.065 47.7441 128.065H0V2.46279ZM32.8904 104.493H39.4331C42.616 104.493 45.1506 104.082 47.0368 103.261C48.923 102.323 50.3376 100.447 51.2807 97.6319C52.3417 94.8173 52.9901 90.8299 53.2259 85.6698C53.5795 80.5097 53.7563 73.7077 53.7563 65.2639C53.7563 56.82 53.5795 50.018 53.2259 44.8579C52.9901 39.6978 52.3417 35.7104 51.2807 32.8958C50.3376 30.0812 48.923 28.2634 47.0368 27.4425C45.1506 26.5043 42.616 26.0352 39.4331 26.0352H32.8904V104.493Z" fill="white" />
    <path d="M96.7647 2.46279H129.655V128.065H96.7647V2.46279Z" fill="white" />
    <path d="M130.675 2.46279H165.157L178.419 97.1042H178.772L192.035 2.46279H227.047L199.815 128.065H157.376L130.675 2.46279Z" fill="white" />
    <path d="M228.732 2.46279H303.532V29.2016H261.623V50.6631H300.879V76.3464H261.623V101.326H305.123V128.065H228.732V2.46279Z" fill="white" />
    <path d="M346.501 55.5886H353.044C357.759 55.5886 361.237 54.24 363.477 51.5426C365.717 48.728 366.837 45.0925 366.837 40.636C366.837 35.3586 365.717 31.6058 363.477 29.3775C361.355 27.1493 357.818 26.0352 352.867 26.0352H346.501V55.5886ZM313.611 2.46279H365.068C371.316 2.46279 376.621 3.28372 380.983 4.92558C385.463 6.56743 389.058 8.91294 391.77 11.9621C394.599 14.894 396.603 18.4123 397.782 22.5169C399.079 26.6216 399.727 31.078 399.727 35.8863C399.727 44.3302 398.018 51.1322 394.599 56.2923C391.18 61.4524 385.699 64.6189 378.154 65.7916V66.1434C386.052 66.8471 391.475 69.6031 394.422 74.4114C397.369 79.2197 398.843 85.8457 398.843 94.2896V105.02C398.843 106.662 398.843 108.363 398.843 110.122C398.843 111.881 398.902 113.581 399.02 115.223C399.138 116.748 399.315 118.155 399.55 119.445C399.786 120.735 400.081 121.732 400.434 122.436C400.906 123.139 401.378 123.843 401.849 124.547C402.321 125.133 403.087 125.719 404.148 126.306V128.065H369.136C368.31 126.423 367.662 124.488 367.19 122.26C366.837 120.032 366.542 117.803 366.306 115.575C366.188 113.347 366.07 111.236 365.953 109.242C365.953 107.131 365.953 105.431 365.953 104.141V95.6969C365.953 92.1786 365.776 89.2467 365.422 86.9012C365.068 84.5557 364.42 82.7379 363.477 81.4479C362.652 80.0406 361.532 79.1024 360.117 78.6333C358.82 78.0469 357.17 77.7537 355.166 77.7537H346.501V128.065H313.611V2.46279Z" fill="white" />
    <path d="M454.053 59.8106H496.492V128.065H474.211L473.504 116.982H473.15C472.089 119.68 470.557 121.908 468.553 123.667C466.667 125.426 464.486 126.775 462.01 127.713C459.652 128.534 457.059 129.12 454.229 129.472C451.518 129.824 448.748 130 445.918 130C437.549 130 430.947 128.651 426.113 125.954C421.28 123.139 417.626 118.976 415.15 113.464C412.674 107.952 411.083 101.15 410.376 93.0582C409.786 84.9662 409.491 75.7014 409.491 65.2639C409.491 54.7091 410.081 45.3857 411.26 37.2936C412.557 29.2016 414.914 22.3996 418.333 16.8877C421.87 11.3757 426.703 7.21245 432.833 4.39784C438.963 1.46595 446.862 0 456.528 0C462.305 0 467.61 0.762295 472.443 2.28688C477.394 3.81146 481.638 6.27425 485.175 9.67524C488.711 13.0762 491.482 17.4741 493.486 22.8687C495.49 28.1461 496.492 34.5963 496.492 42.2192H464.662C464.662 35.5345 464.073 30.6676 462.894 27.6184C461.715 23.9829 459.181 22.1651 455.29 22.1651C452.579 22.1651 450.398 22.8101 448.748 24.1001C447.215 25.3902 446.036 27.677 445.211 30.9608C444.504 34.2445 444.032 38.7009 443.796 44.3302C443.561 49.8421 443.443 56.82 443.443 65.2639C443.443 73.7077 443.561 80.7442 443.796 86.3735C444.032 91.8854 444.504 96.2833 445.211 99.567C446.036 102.851 447.156 105.138 448.571 106.428C450.103 107.718 452.108 108.363 454.583 108.363C456.351 108.363 457.884 108.011 459.181 107.307C460.595 106.604 461.774 105.313 462.717 103.437C463.66 101.443 464.368 98.746 464.839 95.3451C465.429 91.8268 465.723 87.3703 465.723 81.9756H454.053V59.8106Z" fill="white" />
    <path d="M506.335 2.46279H581.134V29.2016H539.225V50.6631H578.482V76.3464H539.225V101.326H582.726V128.065H506.335V2.46279Z" fill="white" />
    <path d="M591.213 2.46279H627.994L649.214 79.8647H649.567V2.46279H680.336V128.065H644.262L622.335 50.4871H621.982V128.065H591.213V2.46279Z" fill="white" />
    <path d="M708.946 30.2571H684.544V2.46279H766.593V30.2571H741.837V128.065H708.946V30.2571Z" fill="white" />
    <path d="M847.505 28.1461L838.487 81.4479H856.877L847.859 28.1461H847.505ZM868.371 2.46279L899.139 128.065H864.127L860.767 106.076H834.596L831.237 128.065H796.755L826.993 2.46279H868.371Z" fill="white" />
    <path d="M943.464 59.8106H985.904V128.065H963.623L962.916 116.982H962.562C961.501 119.68 959.969 121.908 957.964 123.667C956.078 125.426 953.897 126.775 951.422 127.713C949.064 128.534 946.471 129.12 943.641 129.472C940.93 129.824 938.16 130 935.33 130C926.96 130 920.359 128.651 915.525 125.954C910.692 123.139 907.037 118.976 904.562 113.464C902.086 107.952 900.495 101.15 899.787 93.0582C899.198 84.9662 898.903 75.7014 898.903 65.2639C898.903 54.7091 899.493 45.3857 900.672 37.2936C901.968 29.2016 904.326 22.3996 907.745 16.8877C911.281 11.3757 916.115 7.21245 922.245 4.39784C928.375 1.46595 936.273 0 945.94 0C951.717 0 957.021 0.762295 961.855 2.28688C966.806 3.81146 971.05 6.27425 974.587 9.67524C978.123 13.0762 980.893 17.4741 982.898 22.8687C984.902 28.1461 985.904 34.5963 985.904 42.2192H954.074C954.074 35.5345 953.485 30.6676 952.306 27.6184C951.127 23.9829 948.592 22.1651 944.702 22.1651C941.991 22.1651 939.81 22.8101 938.16 24.1001C936.627 25.3902 935.448 27.677 934.623 30.9608C933.916 34.2445 933.444 38.7009 933.208 44.3302C932.973 49.8421 932.855 56.82 932.855 65.2639C932.855 73.7077 932.973 80.7442 933.208 86.3735C933.444 91.8854 933.916 96.2833 934.623 99.567C935.448 102.851 936.568 105.138 937.983 106.428C939.515 107.718 941.519 108.363 943.995 108.363C945.763 108.363 947.296 108.011 948.593 107.307C950.007 106.604 951.186 105.313 952.129 103.437C953.072 101.443 953.779 98.746 954.251 95.3451C954.84 91.8268 955.135 87.3703 955.135 81.9756H943.464V59.8106Z" fill="white" />
    <path d="M995.747 2.46279H1070.55V29.2016H1028.64V50.6631H1067.89V76.3464H1028.64V101.326H1072.14V128.065H995.747V2.46279Z" fill="white" />
    <path d="M1080.63 2.46279H1117.41L1138.63 79.8647H1138.98V2.46279H1169.75V128.065H1133.67L1111.75 50.4871H1111.39V128.065H1080.63V2.46279Z" fill="white" />
    <path d="M1232.13 40.636C1232.13 33.4822 1231.43 28.6152 1230.01 26.0352C1228.6 23.4551 1226.3 22.1651 1223.11 22.1651C1220.64 22.1651 1218.63 22.8101 1217.1 24.1001C1215.69 25.3902 1214.57 27.677 1213.74 30.9608C1213.03 34.2445 1212.56 38.7009 1212.33 44.3302C1212.09 49.8421 1211.97 56.82 1211.97 65.2639C1211.97 73.7077 1212.09 80.7442 1212.33 86.3735C1212.56 91.8854 1213.03 96.2833 1213.74 99.567C1214.57 102.851 1215.69 105.138 1217.1 106.428C1218.63 107.718 1220.64 108.363 1223.11 108.363C1225.12 108.363 1226.77 107.894 1228.07 106.955C1229.36 105.9 1230.36 104.199 1231.07 101.854C1231.78 99.5083 1232.25 96.4005 1232.49 92.5304C1232.84 88.5431 1233.02 83.5589 1233.02 77.5778H1264.85V86.9012C1264.85 95.6969 1263.67 102.909 1261.31 108.539C1258.95 114.168 1255.83 118.624 1251.94 121.908C1248.05 125.074 1243.57 127.185 1238.5 128.241C1233.55 129.414 1228.42 130 1223.11 130C1213.92 130 1206.37 128.886 1200.48 126.658C1194.59 124.429 1189.99 120.735 1186.69 115.575C1183.39 110.298 1181.09 103.554 1179.79 95.3451C1178.61 87.0185 1178.02 76.9914 1178.02 65.2639C1178.02 53.3018 1178.73 43.1574 1180.14 34.8309C1181.68 26.5043 1184.21 19.7609 1187.75 14.6008C1191.28 9.44069 1195.94 5.74651 1201.72 3.51828C1207.49 1.17276 1214.63 0 1223.11 0C1230.54 0 1236.85 0.938207 1242.04 2.81462C1247.22 4.69103 1251.41 7.38837 1254.59 10.9066C1257.89 14.3076 1260.25 18.4709 1261.66 23.3965C1263.2 28.2048 1263.96 33.6581 1263.96 39.7564V47.6725H1232.13V40.636Z" fill="white" />
    <path d="M1294.93 78.6333L1263.27 2.46279H1298.82L1311.19 46.2652H1311.55L1323.93 2.46279H1360L1327.82 78.6333V128.065H1294.93V78.6333Z" fill="white" />
  </svg>
);
