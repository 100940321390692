import React from 'react';

import './styles.css';

function Circle() {
  return (
    <div className="circle" />
  );
}

export default Circle;
