import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { MountainBreezeStyles } from './BackStageStyles';
import showTextByLetter from '../../../../utils/ShowTextByLetter/ShowTextByLetter';
import { useTranslation } from 'react-i18next';

function MountainBreezeBackStage() {
  const { t } = useTranslation('case_mountain_breeze');

  return (
    <MountainBreezeStyles>
      <div className="container">
        <h2 className="h2">
          {showTextByLetter({ text: t('backstage_title'), delay: 40 })}
        </h2>

        <div className="players">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://youtu.be/88bE7koVaA4"
              width="100%"
              height="100%"
              controls
            />
          </div>

          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://youtu.be/baeaiP2eELs"
              width="100%"
              height="100%"
              controls
            />
          </div>
        </div>
      </div>
    </MountainBreezeStyles>
  );
}

export default MountainBreezeBackStage;
