import React from 'react';
import { BannerStyles } from './BannerStyles';

import Smile from '../../../assets/icons/smile/Smile';
import { Link } from 'react-router-dom';
import Circle from '../../other/circle/Circle';

import bannerImage from '../../../assets/images/main/divergent-banner.jpg';
import MarqueeLine from '../../other/marquee/MarqueeLine';
import showTextByLetter from '../../../utils/ShowTextByLetter/ShowTextByLetter';
import { InView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

function Banner() {
  const { t, i18n } = useTranslation('main');

  return (
    <BannerStyles>
      <div className="container content">
        <h1>{showTextByLetter({ text: t('banner_title'), delay: 40 })}</h1>

        <div className="bannerImageContainer">
          <img src={bannerImage} alt="DIVERGENT" />

          <InView onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)} className="smile">
            <Smile />
          </InView>
        </div>

        <InView onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)} className="textWrapper">
          <h3>{t('banner_description_title')}</h3>

          <div className="rightSide">
            <h4>
              {t('banner_subtitle')}
            </h4>

            <Link to={`/${i18n.language}/form`}>
              <Circle />

              {t('banner_get_consultation')}
            </Link>
          </div>
        </InView>
      </div>

      <MarqueeLine background="#439000" />
    </BannerStyles>
  );
}

export default Banner;
