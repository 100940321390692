import styled from 'styled-components';

export const CasesStyles = styled.div`
  padding: 80px 0;
  
  @media (min-width: 768px) {
    padding: 100px 0;
  }
  
  .h2 {
    margin-bottom: 40px;
    
    @media (min-width: 768px) {
      margin-bottom: 70px;
    }
  }
  
  ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
    
    margin-bottom: 24px;
    
    @media (min-width: 1024px) {
      gap: 40px;
      
      margin-bottom: 41px;
    }
    
    li {
      position: relative;
      opacity: 0;
      
      transition: 1s ease opacity, .3s ease text-shadow;

      &:hover {
        text-shadow: 2px 3px 2px #FF7300;
        
        &::after {
          background: rgba(0, 0, 0, 0.4);
        }
      }
      
      &.inView {
        opacity: 1;
        
        &::after {
          width: 100vw;
        }
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 0;
        transform: translateX(-20px);
        height: 2px;
        background: rgba(0, 0, 0, 0.2);
        
        transition: 1s ease width, background-color .3s;

        @media (min-width: 1360px) {
          bottom: -20px;
          transform: translateX(calc(-1 * (-1360px - -100vw) / 2));
        }
      }
    }
    
    a {
      display: flex;
      align-items: center;
      gap: 14px;

      font-size: 29px;
      font-weight: 900;
      line-height: 39px;
      text-transform: uppercase;
      color: #000;

      @media (min-width: 768px) {
        font-size: 50px;
        line-height: 55px;
      }

      @media (min-width: 1024px) {
        gap: 30px;

        font-size: 90px;
        line-height: 75px;
      }
      
      img {
        height: 30px;
        
        @media (min-width: 768px) {
          height: 55px;
        }
        
        @media (min-width: 1024px) {
          height: 65px;
        }
      }
    }
  }
  
  .linkToAllCases {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 29px;
    font-weight: 900;
    line-height: 39px;
    text-transform: uppercase;
    color: #000;

    @media (min-width: 768px) {
      font-size: 50px;
      line-height: 55px;
    }

    @media (min-width: 1024px) {
      gap: 30px;

      font-size: 90px;
      line-height: 75px;
    }

    transition: 1s ease opacity, .3s ease text-shadow;

    &:hover {
      text-shadow: 2px 3px 2px #FF7300;

      &::after {
        background: rgba(0, 0, 0, 0.4);
      }
    };
    
    .dot {
      width: 14px;
      height: 14px;
      
      border-radius: 100%;
      background-color: #000;
      
      @media (min-width: 768px) {
        width: 18px;
        height: 18px;
      }
    }
  }
`;
