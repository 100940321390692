import styled from 'styled-components';

export const ResultsStyles = styled.div`
  padding: 80px 0 0;
  
  @media (min-width: 768px) {
    padding: 100px 0 0;
  }
  
  .results {
    display: flex;
    flex-direction: column;

    gap: 20px;
    
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    
    @media (min-width: 1024px) {
      grid-template-rows: 1fr 1fr;
    }

    @media (min-width: 768px) {
      width: auto;
      flex-direction: row;
    }
    
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      
      &:nth-child(2) {
        display: none;
      }
      
      @media (min-width: 768px) {
        width: calc(50% - 10px);

        &:nth-child(2) {
          display: block;
        }
      }
    }
  }
`;
