import styled from 'styled-components';

export const MenuStyles = styled.div`
  position: absolute;
  top: 65px;
  
  display: flex;
  flex-direction: column;
  
  transform: translateY(-100%);
  transition: 0.4s ease transform, 0.4s ease opacity;
  
  min-height: calc(100vh - 65px);
  width: 100vw;
  
  opacity: 0;
  background: #FF7300;
  
  &.greenBg {
    background-color: #439000;
  }
  
  &.open {
    transform: translateY(0);
    opacity: 1;
  }
  
  @media (min-width: 768px) {
    top: 107px;
    
    min-height: calc(100vh - 107px);
  }

  nav {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    .linkWrapper {
      padding: 20px 0;
      
      @media (min-width: 768px) {
        padding: 10px 0;
      }
    }
    
    .greenContainer {
      flex-grow: 1;
      height: calc(100% + 50px);
    }
    
    .bgGreen {
      margin-top: -34px;
      background: #439000;
      
      @media (min-width: 768px) {
        margin-top: -40px;
      }
    }
    
    .bgBlack {
      background: #000;
    }
    
    .bgOrange {
      background: #FF7300;
      margin-top: -34px;

      @media (min-width: 768px) {
        margin-top: -40px;
      }
    }
    
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        .circle {
          background-color: #FFF;

          &.black {
            background-color: #000;
          }
        }
      }

      .circle {
        width: 18px;
        height: 18px;
        
        box-sizing: border-box;
        border: 5px solid #FFF;
        border-radius: 100%;
        
        transition: .3s ease background-color;
        
        &.black {
          border-color: #000;
        }
      }
      
      .black {
        color: #000;
      }

      .white {
        color: #FFF;
      }
      
      a {
        width: 100%;
        
        color: #FFF;

        font-size: 48px;
        font-weight: 900;

        @media (min-width: 768px) {
          font-size: 100px;
        }
      }
    }
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    padding-top: 24px;
    padding-bottom: 32px;

    .emailLink {
      display: none;
    }
    
    @media (min-width: 768px) {
      padding-top: 40px;
      padding-bottom: 30px;
      
      .emailLink {
        display: block;
      }
    }
    
    a {
      color: #000;

      font-size: 28px;
      line-height: 28px;
      font-weight: 900;
      text-transform: uppercase;
      
      @media (min-width: 768px) {
        font-size: 39px;
        line-height: 39px;
      }
    }
  }
`;
