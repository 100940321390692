import styled from 'styled-components';

export const MinistryOfVeteransAffairsStyles = styled.div`
  .backstage {
    .mediaWrapper {
      display: flex;
      justify-content: space-between;

      padding: 0 20px 20px 20px;

      img {
        &:nth-child(1) {
          width: 100%;
        }

        &:nth-child(2) {
          display: none;
        }
      }

      @media (min-width: 768px) {
        img {
          &:nth-child(1) {
            width: calc(50% - 10px);
          }

          &:nth-child(2) {
            display: block;
            width: calc(50% - 10px);
          }
        }
      }
    }
  }
`;
