import styled from 'styled-components';

export const MountainBreezeStyles = styled.div`
  padding: 80px 0;
  
  @media (min-width: 768px) {
    padding: 100px 0;
  }
  
  .players {
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
      display: block;

      flex-direction: row;

      .player-wrapper {
        display: inline-block;

        width: calc(50% - 20px);

        &:nth-child(1) {
          margin-right: 40px;
        }
      }
    }
  }
`;
