import React, { useMemo } from 'react';
import showTextByLetter from '../../../utils/ShowTextByLetter/ShowTextByLetter';
import Circle from '../../other/circle/Circle';
import { OtherCasesStyles } from './OtherCasesStyles';
import case1Image from '../../../assets/images/cases/case-1.png';
import case2Image from '../../../assets/images/cases/case-2.jpg';
import case3Image from '../../../assets/images/cases/case-3.png';
import case4Image from '../../../assets/images/cases/case-4.jpg';
import case5Image from '../../../assets/images/cases/case-5.png';
import case7Image from '../../../assets/images/cases/case-7.png';
import case8Image from '../../../assets/images/cases/case-8.jpg';
import { Link, useLocation } from 'react-router-dom';
import { InView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { images, TCases } from '../../../pages/cases/Cases';

const listData: {name: string, path: string, image: string}[] = [
  {
    name: 'ҐАЙД ЗДОРОВИМИ СТОСУНКАМИ',
    path: 'unfpa2022',
    image: case1Image,
  },
  {
    name: 'МИКОЛАЙЧУК OPEN',
    path: 'mykolaichuk',
    image: case2Image,
  },
  {
    name: 'Prosecco',
    path: 'prosecco',
    image: case3Image,
  },
  {
    name: 'РІЧ У ТІМ 2.0',
    path: 'richutim',
    image: case4Image,
  },
  {
    name: 'Jerry Heil #ЗДН',
    path: 'jerryheilzdn',
    image: case5Image,
  },
  {
    name: 'УТОНУ',
    path: 'utonu',
    image: 'https://optim.tildacdn.com/tild3866-6166-4235-b536-306631623461/-/resize/480x480/-/format/webp/IMG_5506.JPG',
  },
  {
    name: 'MORPHINE 13',
    path: 'morphine13',
    image: case7Image,
  },
  {
    name: 'ҐАЙД ЗДОРОВИМИ СТОСУНКАМИ',
    path: 'unfpa',
    image: case8Image,
  },
];

function getRandomElementsFromArray<T>(array: T[], count: number): T[] {
  const result: T[] = [];
  const clonedArray = [...array];

  for (let i = 0; i < count; i++) {
    const randomIndex = Math.floor(Math.random() * clonedArray.length);
    result.push(clonedArray[randomIndex]);
    clonedArray.splice(randomIndex, 1);
  }

  return result;
}

const arrowIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h306.7L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
  </svg>
);

function OtherCases() {
  const { pathname } = useLocation();

  const { i18n, t } = useTranslation('works');
  const { t: tLayout } = useTranslation('layout');

  const cases: TCases = useMemo(() => {
    const casesFromJson = t('list', { returnObjects: true });

    return casesFromJson.map((caseItem, index) => ({ ...caseItem, img: images[index] || '' }));
  }, [i18n.language]);

  const random4ElementsListData: TCases = useMemo(() => {
    const splittedPathname = pathname.split('/');
    const caseName = splittedPathname[splittedPathname.length - 1];
    const casesWithoutWatchedCase = cases.filter((caseItem) => caseItem.path !== caseName);

    return getRandomElementsFromArray(casesWithoutWatchedCase, 4);
  }, [cases]);

  return (
    <OtherCasesStyles>
      <div className="container">
        <div className="headerWrapper">
          <h2 className="h2">{showTextByLetter({ text: tLayout('other_cases_title'), delay: 60 })}</h2>

        </div>

        <div className="contactUsLinksWrapper">
          {random4ElementsListData.map((caseItem) => (
            <Link key={caseItem.title} to={`/${i18n.language}/cases/${caseItem.path}`} rel="noreferrer">
              <InView as="div" onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)} className="linkContent">
                <div className="imageWrapper">
                  <img src={caseItem.img} alt={caseItem.title} />
                </div>

                <div className="nameWrapper">
                  <Circle />

                  {caseItem.title}
                </div>
              </InView>
            </Link>
          ))}
        </div>
      </div>
    </OtherCasesStyles>
  );
}

export default OtherCases;
