import styled from 'styled-components';

export const GoToFormSectionStyles = styled.div`
  background-color: #FF7300;
  
  padding: 40px 0;
  
  @media (min-width: 768px) {
    padding: 80px 0 40px;
  }
  
  .container {
    display: flex;
    flex-direction: column;

    gap: 50px;

    @media (min-width: 768px) {
      gap: 280px;
    }
  }
  
  h2 {
    @media (min-width: 768px) {
      max-width: 600px;
    }
  }
  
  h2, a {
    color: #000;
    font-size: 48px;
    font-weight: 900;
    line-height: 52px;
    letter-spacing: 0;
    
    @media (min-width: 768px) {
      font-size: 100px;
      line-height: 90px;
    }
  }
  
  a {
    transition: .3s text-shadow;

    &:hover {
      text-shadow: 2px 3px 2px rgba(255, 255, 255, 0.6);
    }
  }
  
  .smileIcon {
    width: 106px;
    height: 106px;
    
    @media (min-width: 768px) {
      width: 126px;
      height: 126px;
    }
  }
  
  .sectionFooter {
    display: flex;
    
    flex-direction: column;
    gap: 75px;
    
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      
      align-items: flex-end;
    }
    
    span {
      text-align: right;
    }
  }
  
  .sectionFooter, h2 {
    opacity: 0;
    transition: 1s ease opacity;
    
    &.inView {
      opacity: 1;
    }
  }
`;
