import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { ResultsStyles } from './ResultsStyles';
import showTextByLetter from '../../../../utils/ShowTextByLetter/ShowTextByLetter';
import { useTranslation } from 'react-i18next';

function Results() {
  const { t } = useTranslation('case_ufnpa_1');

  return (
    <ResultsStyles>
      <div className="container">
        <h2 className="h2">{showTextByLetter({ text: t('results_title'), delay: 40 })}</h2>

        <div className="videosWrapper">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://youtu.be/YbX8ziCZSIA"
              width="100%"
              height="100%"
              controls
            />
          </div>

          <div className="wrapper">
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url="https://youtu.be/4Ux_f5cG8l8"
                width="100%"
                height="100%"
                controls
              />
            </div>

            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url="https://youtu.be/2tfTsWKChTs"
                width="100%"
                height="100%"
                controls
              />
            </div>
          </div>
        </div>
      </div>
    </ResultsStyles>
  );
}

export default Results;
