import styled, { keyframes } from 'styled-components';

const elementsEnteringToPage = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`;

export const FormStyles = styled.div`
  display: flex;
  flex-direction: column;
  
  min-height: calc(100dvh - 68px);
  
  padding: 60px 0 20px;
  box-sizing: border-box;
  
  @media (min-width: 768px) {
    padding: 80px 0 40px;

    min-height: calc(100dvh - 111px);
  }
  
  background-color: #fff;
  transition: .3s ease background-color;
  
  &.submited {
    background-color: #439000;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  
  .formSendedContainer {
    justify-content: space-between;
    
    h2 {
      max-width: 1200px;
    }
    
    .formSendedFooter {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .linkToMainPage {
      color: #000;
    }
    
    .smileIcon {
      width: 106.64px;
      height: 106.64px;
      
      transform: translateY(25px);
      
      @media (min-width: 768px) {
        width: 126.64px;
        height: 126.64px;
      }
    }
  }

  .text90 {
    font-size: 48px;
    font-weight: 900;
    line-height: 52px;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 90px;
      line-height: 65px;
    }
  }
  
  .text100 {
    font-size: 48px;
    font-weight: 900;
    line-height: 59px;
    text-transform: uppercase;
    letter-spacing: -5px;

    @media (min-width: 768px) {
      font-size: 100px;
      line-height: 122px;
    }
  }
  
  .titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .step {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    .stepHead {
      padding-top: 30px;
      max-width: 787px;

      animation: 0.8s linear running ${elementsEnteringToPage};
    }
    
    .optionsButtonsWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    
    .stepHead {
      display: flex;
      flex-direction: column;
      gap: 20px;
      
      @media (min-width: 768px) {
        gap: 30px;
      }
      
      .inputsWrapper {
        padding-top: 60px;
        
        display: flex;
        flex-direction: column;
        gap: 60px;
      }
    }
    
    h2 {
      font-size: 29px;
      font-weight: 900;
      line-height: 39px;
      
      text-transform: uppercase;
      
      @media (min-width: 768px) {
        font-size: 38px;
        font-weight: 900;
        line-height: 46px;
      }
    }
    
    .stepFooter {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      
      .nextButton {
        transition: .3s ease text-shadow, background-color 0.3s, color 0.3s;

        &:hover:not(:disabled) {
          text-shadow: 2px 3px 2px #FF7300;
        }
      }
      
      .backButton {
        font-size: 20px;
        font-weight: 900;
        line-height: 25px;

        @media (min-width: 768px) {
          font-size: 22px;
          line-height: 27px;
        }
      }
    }
  }
`;
