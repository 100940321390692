import styled from 'styled-components';

export const CasesBaseStyles = styled.div`
  .blockPaddingBottom {
    padding-bottom: 40px;
    
    @media (min-width: 1024px) {
      padding-bottom: 100px;
    }
  }

  .player-wrapper {
    height: auto;
    width: auto;

    .react-player {
      padding-top: 56.25%;
      position: relative;
    }

    .react-player > div {
      top: 0;

      position: absolute;
    }
  }
`;
