import React from 'react';
import { PolicyStyles } from './PolicyStyles';
import { useTranslation } from 'react-i18next';

function Policy() {
  const { t } = useTranslation('policy');

  return (
    <PolicyStyles className="container">
      <div className="block">
        <h1>{t('policy_1')}</h1>

        <p>
          {t('policy_2')}
          {' '}
          <a href={process.env.REACT_APP_BASE_URL}>{process.env.REACT_APP_BASE_URL}</a>
          {' '}
          {t('policy_3')}
        </p>
      </div>

      <div className="block">
        <h2>{t('policy_4')}</h2>

        <p>{t('policy_5')}</p>

        <ul>
          <li>
            <strong>{t('policy_6')}</strong>
            {' '}
            -
            {' '}
            {t('policy_7')}
          </li>

          <li>
            <strong>{t('policy_8')}</strong>
            {' '}
            -
            {' '}
            {t('policy_9')}
          </li>

          <li>
            <strong>{t('policy_10')}</strong>
            {' '}
            -
            {' '}
            {t('policy_11')}
          </li>

          <li>
            <strong>{t('policy_12')}</strong>
            {' '}
            -
            {' '}
            {t('policy_13')}
          </li>

          <li>
            <strong>{t('policy_14')}</strong>
            {' '}
            -
            {' '}
            {t('policy_15')}
          </li>

          <li>
            <strong>{t('policy_16')}</strong>
            {' '}
            -
            {' '}
            {t('policy_17')}
          </li>

          <li>
            <strong>{t('policy_18')}</strong>
            {' '}
            -
            {' '}
            {t('policy_19')}
          </li>

          <li>
            <strong>{t('policy_20')}</strong>
            {' '}
            -
            {' '}
            {t('policy_21')}
            {' '}
            <a href={process.env.REACT_APP_BASE_URL}>{process.env.REACT_APP_BASE_URL}</a>
            {' '}
            {t('policy_22')}
          </li>
        </ul>
      </div>

      <div className="block">
        <h2>{t('policy_23')}</h2>

        <ol>
          <li>
            <span className="numberingText">1.1</span>

            <p>
              {t('policy_24')}
            </p>
          </li>

          <li>
            <span className="numberingText">1.2</span>

            <p>
              {t('policy_25')}
            </p>
          </li>

          <li>
            <span className="numberingText">1.3</span>

            <p>
              {t('policy_26')}
              {' '}
              <a href={process.env.REACT_APP_BASE_URL}>{process.env.REACT_APP_BASE_URL}</a>
              {' '}
              {t('policy_27')}
            </p>
          </li>

          <li>
            <span className="numberingText">1.4</span>

            <p>
              {t('policy_28')}
            </p>
          </li>

          <li>
            <span className="numberingText">1.5</span>

            <p>
              {t('policy_29')}
              {' '}
              <a href={process.env.REACT_APP_BASE_URL}>{process.env.REACT_APP_BASE_URL}</a>
              {t('policy_30')}
            </p>
          </li>

          <li>
            <span className="numberingText">1.6</span>

            <p>
              {t('policy_31')}
            </p>
          </li>

          <li>
            <span className="numberingText">1.7</span>

            <p>
              {t('policy_32')}
              {' '}
              <a href={process.env.REACT_APP_BASE_URL}>{process.env.REACT_APP_BASE_URL}</a>
              {' '}
              {t('policy_33')}
            </p>
          </li>

          <li>
            <span className="numberingText">1.8</span>

            <p>
              {t('policy_34')}
            </p>
          </li>
        </ol>
      </div>

      <div className="block">
        <h2>{t('policy_35')}</h2>

        <ol>
          <li>
            <span className="numberingText">2.1</span>

            <p>
              {t('policy_36')}
            </p>
          </li>
          <li>
            <span className="numberingText">2.2</span>

            <p>
              {t('policy_37')}
            </p>
          </li>
          <li>
            <span className="numberingText">2.3</span>

            <p>
              {t('policy_38')}
            </p>
          </li>
          <li>
            <span className="numberingText">2.4</span>

            <p>
              {t('policy_39')}
            </p>
          </li>
          <li>
            <span className="numberingText">2.5</span>

            <p>
              {t('policy_40')}
            </p>
          </li>
          <li>
            <span className="numberingText">2.6</span>

            <p>
              {t('policy_41')}
            </p>
          </li>
          <li>
            <span className="numberingText">2.7</span>

            <p>
              {t('policy_42')}
            </p>
          </li>
          <li>
            <span className="numberingText">2.8</span>

            <p>
              {t('policy_43')}
            </p>
          </li>
          <li>
            <span className="numberingText">2.9</span>

            <p>
              {t('policy_44')}
            </p>
          </li>
          <li>
            <span className="numberingText">2.10</span>

            <p>
              {t('policy_45')}
            </p>
          </li>
          <li className="item-2-11">
            <div className="head">
              <span className="numberingText">2.11</span>

              <p>
                {t('policy_46')}
              </p>
            </div>

            <div className="linksWrapper">
              <a href="https://policies.google.com/privacy?hl=en" rel="noreferrer">1. Google LLC (Google Ads, Google Admob, Google Firebase</a>
              <a href="https://www.facebook.com/privacy/explanation" rel="noreferrer">2. Facebook Inc.</a>
            </div>

            <span className="centered">{t('policy_47')}</span>
          </li>
        </ol>
      </div>

      <div className="block">
        <h2>{t('policy_48')}</h2>

        <ol>
          <li>
            <span className="numberingText">3.1</span>

            <p>
              {t('policy_49')}
            </p>
          </li>

          <li>
            <span className="numberingText">3.2</span>

            <p>
              {t('policy_50')}
            </p>
          </li>

          <li>
            <span className="numberingText">3.3</span>

            <p>
              {t('policy_51')}
            </p>
          </li>

          <li>
            <span className="numberingText">3.4</span>

            <p>
              {t('policy_52')}
            </p>
          </li>
        </ol>
      </div>

      <div className="block">
        <h2>{t('policy_53')}</h2>

        <ol>
          <li>
            <span className="numberingText">4.1</span>

            <p>
              {t('policy_54')}
            </p>
          </li>

          <li>
            <span className="numberingText">4.2</span>

            <p>
              {t('policy_55')}
            </p>
          </li>

          <li>
            <span className="numberingText">4.3</span>

            <p>
              {t('policy_56')}
            </p>
          </li>

          <li>
            <span className="numberingText">4.4</span>

            <p>
              {t('policy_57')}
            </p>
          </li>
        </ol>
      </div>

      <div className="block">
        <h2>
          {t('policy_58')}
        </h2>

        <ol>
          <li>
            <span className="numberingText">5.1</span>

            <p>
              {t('policy_59')}
              {' '}
              <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>{process.env.REACT_APP_EMAIL}</a>
              .
            </p>
          </li>
        </ol>
      </div>

      <div className="block">
        <h2>{t('policy_60')}</h2>

        <ol>
          <li>
            <span className="numberingText">6.1</span>

            <p>
              {t('policy_61')}
            </p>
          </li>

          <li>
            <span className="numberingText">6.2</span>

            <p>
              {t('policy_62')}
            </p>
          </li>
        </ol>
      </div>

      <div className="block">
        <h2>{t('policy_63')}</h2>

        <ol>
          <li>
            <span className="numberingText">7.1</span>

            <p>
              {t('policy_64')}
            </p>
          </li>
        </ol>
      </div>

      <div className="block">
        <h2>{t('policy_65')}</h2>

        <ol>
          <li>
            <span className="numberingText">8.1</span>

            <p>
              {t('policy_66')}
            </p>
          </li>

          <li>
            <span className="numberingText">8.2</span>

            <p>
              {t('policy_67')}
            </p>
          </li>

          <li>
            <span className="numberingText">8.3</span>

            <p>
              {t('policy_68')}
            </p>
          </li>

          <li>
            <span className="numberingText">8.4</span>

            <p>
              {t('policy_69')}
            </p>
          </li>
        </ol>
      </div>

      <div className="block">
        <h2>{t('policy_70')}</h2>

        <ol>
          <li>
            <span className="numberingText">9.1</span>

            <p>
              {t('policy_71')}
              {' '}
              <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>{process.env.REACT_APP_EMAIL}</a>
              .
            </p>
          </li>
        </ol>
      </div>
    </PolicyStyles>
  );
}

export default Policy;
