import React, { useMemo } from 'react';

import case1Image from '../../../assets/images/cases/case-1.png';
import case2Image from '../../../assets/images/cases/case-2.jpg';
import case3Image from '../../../assets/images/cases/case-3.png';
import case5Image from '../../../assets/images/cases/case-5.png';
import case7Image from '../../../assets/images/cases/case-7.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CasesStyles } from './CasesStyles';
import showTextByLetter from '../../../utils/ShowTextByLetter/ShowTextByLetter';
import { InView } from 'react-intersection-observer';

function Cases() {
  const { i18n, t } = useTranslation('main');

  const listItems: {name: string, path: string, image: string}[] = useMemo(() => [
    {
      name: t('cases_case_1_name'),
      path: 'unfpa2022',
      image: case1Image,
    },
    {
      name: t('cases_case_2_name'),
      path: 'mykolaichuk',
      image: case2Image,
    },
    {
      name: t('cases_case_3_name'),
      path: 'prosecco',
      image: case3Image,
    },
    {
      name: t('cases_case_4_name'),
      path: 'jerryheilzdn',
      image: case5Image,
    },
    {
      name: t('cases_case_5_name'),
      path: 'morphine13',
      image: case7Image,
    },
  ], [i18n.language]);

  return (
    <CasesStyles>
      <div className="container">
        <h2 className="h2">{showTextByLetter({ text: t('cases_title'), delay: 40 })}</h2>

        <ul>
          {listItems.map((listItem) => (
            <InView as="li" onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)}>
              <Link to={`/${i18n.language}/cases/${listItem.path}`}>
                {listItem.name}
                {' '}
                <img src={listItem.image} alt={`${listItem.name}`} />
              </Link>
            </InView>
          ))}
        </ul>

        <Link className="linkToAllCases" to={`/${i18n.language}/cases`}>
          {showTextByLetter({ text: t('cases_go_to_all'), delay: 30 })}

          <div className="dot" />
        </Link>
      </div>
    </CasesStyles>
  );
}

export default Cases;
