import React from 'react';
import { CasesBaseStyles } from '../CasesBaseStyles';
import Banner from '../../../components/cases/banner/Banner';
import BackStage from '../../../components/cases/backStage/BackStage';

import { UNFPA2Styles } from './UNFPA2Styles';
import BannerVideos from '../../../components/cases/ufnpa2/bannerVideos/BannerVideos';
import OtherCases from '../../../components/cases/otherCases/OtherCases';
import { useTranslation } from 'react-i18next';

function UNFPA2() {
  const { t } = useTranslation('case_ufnpa2');

  return (
    <CasesBaseStyles>
      <UNFPA2Styles>
        <Banner
          title={t('banner_title')}
          descriptionBlock={
            {
              title: t('banner_description_block_title'),
              platformName: 'Сайті проекту',
              description: t('banner_description_block_description'),
              category: [t('banner_description_block_category')],
              link: 'https://richutim2021.rozirvykolo.org/',
              clients: [
                t('banner_description_block_client_1'),
                t('banner_description_block_client_2'),
                t('banner_description_block_client_3'),
                t('banner_description_block_client_4'),
              ],
            }
          }
        >
          <BannerVideos />
        </Banner>

        <BackStage
          title={t('backstage_title')}
          videoUrl="https://youtu.be/WQQ6s50rLmA"
          descriptionBlocks={
            [
              {
                description: t('backstage_description_block_1_description'),
                title: t('backstage_description_block_1_title'),
                backgroundColor: '#000000',
              },
              {
                description: t('backstage_description_block_2_description'),
                title: t('backstage_description_block_2_title'),
                backgroundColor: '#439000',
              },
            ]
          }
        />

        <BackStage
          title={t('results_title')}
          titleColor="#000"
          titleWrapperBackgroundColor="#FFF"
          videoUrl="https://youtu.be/8-CURemuPSo"
          descriptionBlocks={
            [
              {
                description: t('results_description_block_1_description'),
                title: t('results_description_block_1_title'),
                backgroundColor: '#000000',
              },
              {
                description: '',
                title: '',
                backgroundColor: '#000000',
              },
            ]
          }
        />
        <OtherCases />
      </UNFPA2Styles>
    </CasesBaseStyles>
  );
}

export default UNFPA2;
