import React from 'react';

function YouTube() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M30.1439 13.1003C31.176 13.379 31.9872 14.1923 32.2614 15.2232C32.7603 17.0941 32.7603 21.0001 32.7603 21.0001C32.7603 21.0001 32.7603 24.9061 32.2614 26.777C31.9834 27.8117 31.1722 28.625 30.1439 28.8999C28.2778 29.4001 20.7903 29.4001 20.7903 29.4001C20.7903 29.4001 13.3067 29.4001 11.4367 28.8999C10.4046 28.6212 9.59343 27.8079 9.31922 26.777C8.82031 24.9061 8.82031 21.0001 8.82031 21.0001C8.82031 21.0001 8.82031 17.0941 9.31922 15.2232C9.59724 14.1885 10.4084 13.3752 11.4367 13.1003C13.3067 12.6001 20.7903 12.6001 20.7903 12.6001C20.7903 12.6001 28.2778 12.6001 30.1439 13.1003ZM24.6178 21.0001L18.3986 24.6006V17.3996L24.6178 21.0001Z" fill="white" />
    </svg>
  );
}

export default YouTube;
