import React from 'react';
import { CasesBaseStyles } from '../CasesBaseStyles';
import Banner from '../../../components/cases/banner/Banner';
import BackStage from '../../../components/cases/backStage/BackStage';

import bannerImage from '../../../assets/images/unilever/cases-banner.jpg';
import { UnileverStyles } from './UnileverStyles';
import Results from '../../../components/cases/ufnpa3/results/Results';
import OtherCases from '../../../components/cases/otherCases/OtherCases';
import { useTranslation } from 'react-i18next';

function Unilever() {
  const { t } = useTranslation('case_unilever');

  return (
    <CasesBaseStyles>
      <UnileverStyles>
        <Banner
          title={t('banner_title')}
          videoUrl="https://youtu.be/QdL6GhS-MqY"
          descriptionBlock={
            {
              title: t('banner_description_block_title'),
              platformName: 'YouTube',
              description: t('banner_description_block_description'),
              category: [t('banner_description_block_category')],
              link: 'https://youtu.be/QdL6GhS-MqY',
              clients: [t('banner_description_block_client_1')],
            }
          }
        />

        <BackStage
          title={t('backstage_title')}
          image={bannerImage}
          descriptionBlocks={
            [
              {
                description: t('backstage_description_block_1_description'),
                title: t('backstage_description_block_1_title'),
                backgroundColor: '#000000',
              },
              {
                description: t('backstage_description_block_2_description'),
                title: t('backstage_description_block_2_title'),
                backgroundColor: '#439000',
              },
            ]
          }
        />

        <Results />

        <OtherCases />
      </UnileverStyles>
    </CasesBaseStyles>
  );
}

export default Unilever;
