import React from 'react';
import { ResultsStyles } from './ResultsStyles';

import img1 from '../../../../assets/images/ministryOfVeterans/results-1.png';
import img2 from '../../../../assets/images/ministryOfVeterans/results-2.png';
import { useTranslation } from 'react-i18next';
import BackStage from '../../backStage/BackStage';

function Results() {
  const { t } = useTranslation('case_ministry_of_veterans_affairs_of_ukraine');

  return (
    <ResultsStyles>
      <BackStage
        title={t('results_title')}
        titleColor="#000"
        titleWrapperBackgroundColor="#FFF"
        extraClasses="decisionBackStage"
        descriptionBlocks={
          [
            {
              title: t('results_description_block_1_title'),
              description: t('results_description_block_1_description'),
              backgroundColor: '#000000',
            },
            {
              title: t('results_description_block_2_title'),
              description: t('results_description_block_2_description'),
              backgroundColor: '#439000',
            },
          ]
        }
      >
        <div className="results imagesWrapper">
          <img src={img1} alt="" loading="lazy" />

          <img src={img2} alt="" loading="lazy" />
        </div>
      </BackStage>
    </ResultsStyles>
  );
}

export default Results;
