import styled from 'styled-components';

export const BannerVideosStyles = styled.div`
  position: relative;
  z-index: 1;

  .player-wrapper {
    height: auto;
    width: auto;

    .react-player {
      padding-top: 127% !important;
      position: relative;
    }

    .react-player > div {
      top: 0;

      position: absolute;
    }
  }
  
  .videosWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    @media (min-width: 1024px) {
      display: block;
    }
    
    .player-wrapper {
      @media (min-width: 1024px) {
        display: inline-block;
        
        width: calc(33.3% - 20px);
        
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
    }
  }
`;
