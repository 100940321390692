import React, { useState } from 'react';
import { HeaderStyles } from './HeaderStyles';
import { Link } from 'react-router-dom';
import Menu from './menu/Menu';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

function Header({ greenBg }: {greenBg?: boolean}) {
  const { i18n, t } = useTranslation('layout');
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  function menuHandler() {
    setMenuOpen(!menuOpen);
  }

  return (
    <HeaderStyles id="header" className={classNames({ open: menuOpen, greenBg })}>
      <Menu greenBg={greenBg} menuOpen={menuOpen} handleCloseMenu={() => setMenuOpen(false)} />

      <div className="container">
        <div className="leftSide">
          <Link to="/" className="logo">DIVERGENT</Link>

          <div className="languagesHandler">
            <button type="button" className={classNames({ active: i18n.language === 'en' })} onClick={() => i18n.changeLanguage('en')}>
              EN
            </button>

            <button type="button" className={classNames({ active: i18n.language === 'uk' })} onClick={() => i18n.changeLanguage('uk')}>
              UA
            </button>
          </div>
        </div>

        <div className="rightSide">
          <button type="button" onClick={menuHandler} className="menuHandler">
            {menuOpen ? (
              <>
                <span>{t('header_close')}</span>
                <span className="minus">-</span>
              </>
            ) : (
              <>
                <span>{t('header_menu')}</span>
                <span>+</span>
              </>
            )}
          </button>
        </div>
      </div>
    </HeaderStyles>
  );
}

export default Header;
