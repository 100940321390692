import React from 'react';

function Facebook() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
      <path d="M25.7171 22.3118L26.3155 18.513H22.6293V16.0438C22.6293 15.0051 23.1439 13.9901 24.7896 13.9901H26.4891V10.7552C25.4994 10.5975 24.4993 10.5122 23.497 10.5C20.463 10.5 18.4822 12.3222 18.4822 15.6164V18.513H15.1191V22.3118H18.4822V31.5H22.6293V22.3118H25.7171Z" fill="white" />
    </svg>
  );
}

export default Facebook;
