import styled from 'styled-components';

export const OtherCasesStyles = styled.div`
  padding-top: 80px;
  
  @media (min-width: 768px) {
    padding-top: 100px;
  }
  
  .contactUsLinksWrapper {
    position: relative;
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    
    padding-bottom: 80px;
    
    @media (min-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
      
      padding-bottom: 0;
    }

    @media only screen and (min-width: 320px) and (max-width: 1024px) {
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 1px;
        background-color: #00000026;
      }
    }
  }

  a {
    position: relative;

    display: flex;

    &:hover {
      .circle {
        background: #000;
      }

      img {
        transform: scale(1.02);
      }
    }

    
    @media (min-width: 1024px) {
      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          right: -20px;
          height: 100%;
          width: 1px;
          background-color: #00000026;
        }
      }
    }
    
    .linkContent {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 100px;

      opacity: 0;
      transition: 1s ease opacity;

      @media (min-width: 1024px) {
        padding-bottom: 40px;
      }

      &.inView {
        opacity: 1;
      }

      .imageWrapper {
        overflow: hidden;
        border-radius: 8px;
        display: flex;

        img {
          width: 100%;
          transition: .3s ease transform;
        }
      }
    }
  }
  
  .nameWrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    color: #000;

    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    text-overflow: clip;
    
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
`;
