import React from 'react';
import { CasesBaseStyles } from '../CasesBaseStyles';
import Banner from '../../../components/cases/banner/Banner';
import BackStage from '../../../components/cases/backStage/BackStage';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Results from '../../../components/cases/ufnpa1/results/Results';
import OtherCases from '../../../components/cases/otherCases/OtherCases';

function UFNPA1() {
  const { i18n, t } = useTranslation('case_ufnpa_1');

  return (
    <CasesBaseStyles>
      <Banner
        title={t('banner_title')}
        videoUrl="https://youtu.be/l0i4vYu8J-Y"
        descriptionBlock={
          {
            title: t('banner_description_block_title'),
            platformName: 'Youtube',
            description: t('banner_description_block_description'),
            category: [t('banner_description_block_category')],
            link: 'https://youtu.be/l0i4vYu8J-Y',
            clients: [t('banner_description_block_client')],
          }
        }
      />

      <BackStage
        title={t('backstage_title')}
        videoUrl="https://youtu.be/WZ7x5ODfltI"
        descriptionBlocks={
          [
            {
              children: (
                <p className="descriptionBlockDescription">
                  {t('backstage_description_block_1_description_1')}
                  {' '}
                  <Link to={`/${i18n.language}/cases/ufnpa`}>{t('backstage_description_block_1_description_2')}</Link>
                  {' '}
                  {t('backstage_description_block_1_description_3')}
                </p>
              ),
              title: t('backstage_description_block_1_title'),
              backgroundColor: '#000000',
            },
            {
              description: t('backstage_description_block_2_description'),
              title: t('backstage_description_block_2_title'),
              backgroundColor: '#439000',
            },
          ]
        }
      />

      <Results />
      <OtherCases />
    </CasesBaseStyles>
  );
}

export default UFNPA1;
