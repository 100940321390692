import React, { useMemo, useState } from 'react';
import { FooterStyles } from './FooterStyles';
import footerBrandText from '../../assets/icons/footer/FooterBrandText';
import YouTube from '../../assets/icons/footer/socialMedias/YouTube';
import Instagram from '../../assets/icons/footer/socialMedias/Instagram';
import Facebook from '../../assets/icons/footer/socialMedias/Facebook';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';

function Footer() {
  const { i18n, t } = useTranslation('layout');

  const [inputValue, setInputValue] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const isEmailValid = useMemo(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(inputValue);
  }, [inputValue]);

  async function subscribe() {
    try {
      setIsSubmitting(true);

      const data = {
        email: inputValue,

        lang: i18n.language.toUpperCase(),
      };

      emailjs
        .send('service_2ups8ki', 'template_cpvk4kr', data, {
          publicKey: 'scfT6QB9SLCd-lmy0',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            setInputValue('');
          },
          (error: any) => {
            console.log('FAILED...', error.text);
          },
        );
    } catch (e) {
      console.error(`Error: ${e}`);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <FooterStyles>
      <div className="container">
        <div className="content">
          <div className="brandNameContainer">
            <h4>{t('footer_title')}</h4>
          </div>

          <div className="formWrapper">
            <h4 className="formTitle">
              {t('footer_form_title')}
            </h4>

            <form onSubmit={subscribe}>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>
                <input disabled={isSubmitting} placeholder={t('footer_placeholder')} type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} id="emailInput" />

                <button type="submit" className="submitButton" disabled={!isEmailValid || isSubmitting}>
                  {t('footer_subscribe')}
                </button>
              </label>
            </form>
          </div>

          <div className="navigationAndContactsWrapper">
            <div className="leftSide">
              <nav>
                <Link className="link" to={`/${i18n.language}/cases`}>{t('footer_nav_1')}</Link>
              </nav>
            </div>

            <div className="rightSide">
              <div className="locationsAndContacts">
                <a className="link" href={`tel:${process.env.REACT_APP_PHONE}`}>{process.env.REACT_APP_PHONE_FORMATTED}</a>
                <a className="link" href={`mailto:${process.env.REACT_APP_EMAIL}`}>{process.env.REACT_APP_EMAIL}</a>
                {/* <a className="link" href="">location 1</a> */}
              </div>
              <div />

              <div className="socialMedias">
                <a href={process.env.REACT_APP_YOUTUBE} className="youtube">
                  <YouTube />
                </a>
                <a href={process.env.REACT_APP_INSTAGRAM} className="instagram">
                  <Instagram />
                </a>
                <a href={process.env.REACT_APP_FACEBOOK} className="facebook">
                  <Facebook />
                </a>
              </div>
            </div>
          </div>

          <div className="bottomText">
            {footerBrandText}
          </div>
        </div>
      </div>
    </FooterStyles>
  );
}

export default Footer;
