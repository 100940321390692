import React from 'react';
import { ButtonStyles } from './ButtonStyles';
import classNames from 'classnames';

interface ButtonProps {
  text: string;
  icon?: string;
  selected: boolean;
  onClick: any;
}

function Button({
  onClick, selected, text, icon,
}: ButtonProps) {
  return (
    <ButtonStyles onClick={onClick} className={classNames({ selected })}>
      {text}

      {icon && <img src={icon} alt="" />}
    </ButtonStyles>
  );
}

export default Button;
