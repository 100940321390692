import React from 'react';
import { ExpertiseStyles } from './ExpertiseStyles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import expertiseImage from '../../../assets/images/main/expetise-people.png';
import showTextByLetter from '../../../utils/ShowTextByLetter/ShowTextByLetter';
import { InView } from 'react-intersection-observer';

function Expertise() {
  const { i18n, t } = useTranslation('main');

  return (
    <ExpertiseStyles>
      <div className="container">
        <img src={expertiseImage} alt="Dancing people" className="imageMobile" />

        <div className="titleWrapper">
          <h2 className="h2">
            {showTextByLetter({ text: t('expertise_title'), delay: 60 })}
          </h2>

          <div className="dot" />
        </div>

        <div className="listWrapper">
          <ul>
            {/* {listItems.map((text) => <li key={text}>{text}</li>)} */}
            {t('expertise_list', { returnObjects: true }).map((item) => (
              <InView as="li" triggerOnce onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)} key={item.name}>
                <h4>{item.name}</h4>

                <span>{item.description}</span>
              </InView>
            ))}
          </ul>

          <img src={expertiseImage} alt="Dancing people" className="imageDesktop" />
        </div>

        <div className="bottomTextWrapper">
          <Link to={`/${i18n.language}/form`}>{t('expertise_get_in_touch')}</Link>

          <div className="dot" />
        </div>
      </div>
    </ExpertiseStyles>
  );
}

export default Expertise;
