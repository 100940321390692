import React from 'react';
import { InputStyles } from './InputStyles';
import classNames from 'classnames';

interface InputProps {
  value: string,
  placeholder: string,
  error?: boolean,
  filled?: boolean,
  setValue: (enteredValue: string) => void;
}

function Input({
  value, placeholder, setValue, error, filled,
}: InputProps) {
  return (
    <InputStyles
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder={placeholder}
      className={classNames({ error, filled })}
    />
  );
}

export default Input;
