import styled from 'styled-components';

export const BannerStyles = styled.div`
  h1 {
    margin-bottom: 18px;
    
    color: #000;

    font-size: 40px;
    font-weight: 900;

    @media (min-width: 1024px) {
      font-size: 120px;
      letter-spacing: -4.8px;
    }
  }
  
  .mediaWrapper {
    max-width: 1440px;
    margin: auto;
    padding-bottom: 30px;
    
    @media (min-width: 768px) {
      padding-bottom: 60px;
    }

    .player-wrapper {
      height: auto;
      width: auto;

      .react-player {
        padding-top: 56.25%;
        position: relative;
      }

      .react-player > div {
        top: 0;

        position: absolute;
      }
    }
    
    .bannerImage {
      width: 100%;
      height: auto;
    }
  }

  .bannerImageContainer {
    position: relative;

    margin-bottom: 40px;

    @media (min-width: 768px) {
      margin-bottom: 90px;
    }

    img {
      width: 100%;
    }
  }

  .textWrapper {
    display: flex;
    flex-direction: column;

    margin-bottom: 40px;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 102px;
    }

    h2 {
      color: #000;
      font-size: 24px;
      font-weight: 900;
      line-height: 27px;
      text-transform: uppercase;

      margin-bottom: 40px;

      opacity: 0;
      transform: translateY(20px);
      transition: 1s ease opacity, .3s ease transform;

      &.inView {
        opacity: 1;
        transform: translateY(0);
      }
      

      @media (min-width: 768px) {
        margin-bottom: 0;
        max-width: 540px;

        font-size: 39px;
        line-height: 39px;
      }
    }

    .rightSide {
      display: flex;
      flex-direction: column;
      gap: 30px;
      
      opacity: 0;
      transform: translateY(20px);
      transition: 1s ease opacity, .3s ease transform;
      
      &.inView {
        opacity: 1;
        transform: translateY(0);
      }
      
      @media (min-width: 768px) {
        gap: 50px;

        max-width: 503px;
        justify-content: space-between;
      }

      h3 {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        
        white-space: pre-wrap;
      }
      
      .clientInfoBlocksWrapper {
        display: flex;
        flex-direction: column;
        gap: 36px;
        
        @media (min-width: 500px) {
          flex-direction: row;
        }
        
        .clientInfoBlock {
          display: flex;
          flex-direction: column;
          gap: 10px;
          
          .clientInfoBlockTitle {
            font-size: 19px;
            font-weight: 900;
            line-height: 27px;
            letter-spacing: 0;
            text-transform: uppercase;
            
            @media (min-width: 768px) {
              font-size: 39px;
              line-height: 48px;
            }
          }

          ul {
            li {
              font-size: 16px;
              font-weight: 900;
              line-height: 20px;
              letter-spacing: 0;
              
              text-transform: uppercase;

              @media (min-width: 768px) {
                font-size: 20px;
                line-height: 25px;
              }
            }
            
            li:not(:last-child) {
              margin-bottom: 4px;
            }
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        gap: 10px;

        color: #000;

        font-size: 18px;
        font-weight: 900;
        text-transform: uppercase;

        &:hover {
          .circle {
            background: #000;
          }
        }
      }
    }
  }
`;
