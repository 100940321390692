import React from 'react';
import Banner from '../../components/main/banner/Banner';
import Clients from '../../components/main/clients/Clients';
import MarqueeLine from '../../components/other/marquee/MarqueeLine';
import AboutUs from '../../components/main/aboutUs/AboutUs';
import Expertise from '../../components/main/expertise/Expertise';
import Cases from '../../components/main/cases/Cases';
import FollowUs from '../../components/main/followUs/FollowUs';

function Main() {
  return (
    <div>
      <Banner />
      <Clients />
      <MarqueeLine background="#FF7300" />
      <AboutUs />
      <Cases />
      <Expertise />
      <FollowUs />
    </div>
  );
}

export default Main;
