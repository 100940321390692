import React from 'react';
import { CasesBaseStyles } from '../CasesBaseStyles';
import Banner from '../../../components/cases/banner/Banner';
import BackStage from '../../../components/cases/backStage/BackStage';

import bannerImage from '../../../assets/images/unfpa3/banner-image.png';
import { UNFPA3Styles } from './UNFPA3Styles';
import Results from '../../../components/cases/ufnpa3/results/Results';
import OtherCases from '../../../components/cases/otherCases/OtherCases';
import { useTranslation } from 'react-i18next';

function UNFPA3() {
  const { t } = useTranslation('case_ufnpa3');

  return (
    <CasesBaseStyles>
      <UNFPA3Styles>
        <Banner
          title={t('banner_title')}
          image={bannerImage}
          descriptionBlock={
            {
              title: t('banner_description_block_title'),
              platformName: 'TIK TOK',
              description: t('banner_description_block_description'),
              category: [t('banner_description_block_category')],
              link: 'https://www.tiktok.com/@okay_notokay',
              clients: [t('banner_description_block_client')],
            }
          }
        />

        <BackStage
          title={t('backstage_title')}
          videoUrl="https://youtu.be/HHpD20OYDMM"
          descriptionBlocks={
            [
              {
                description: t('backstage_description_block_1_description'),
                title: t('backstage_description_block_1_title'),
                backgroundColor: '#000000',
              },
              {
                description: t('backstage_description_block_2_description'),
                title: t('backstage_description_block_2_title'),
                backgroundColor: '#439000',
              },
            ]
          }
        />

        <Results />
        <OtherCases />
      </UNFPA3Styles>
    </CasesBaseStyles>
  );
}

export default UNFPA3;
