import React from 'react';
import { CasesBaseStyles } from '../CasesBaseStyles';
import Banner from '../../../components/cases/banner/Banner';
import BackStage from '../../../components/cases/backStage/BackStage';

import bannerImage from '../../../assets/images/mountainBreeze/banner-image.png';
import { MountainBreezeStyles } from './MountainBreezeStyles';
import MountainBreezeBackStage from '../../../components/cases/mountainBreeze/backstage/BackStage';
import OtherCases from '../../../components/cases/otherCases/OtherCases';
import { useTranslation } from 'react-i18next';

function MountainBreeze() {
  const { t } = useTranslation('case_mountain_breeze');

  return (
    <CasesBaseStyles>
      <MountainBreezeStyles>
        <Banner
          title="MOUNTAIN BREEZE"
          image={bannerImage}
          descriptionBlock={
            {
              title: t('banner_description_block_title'),
              platformName: 'Youtube',
              description: t('banner_description_block_description'),
              category: [t('banner_description_block_category')],
              link: 'https://youtu.be/uF9ALGHrP2U',
              clients: ['Mountain Breeze'],
            }
          }
        />

        <BackStage
          title={t('results_title')}
          videoUrl="https://youtu.be/uF9ALGHrP2U"
          descriptionBlocks={
            [
              {
                description: t('results_description_block_1_description'),
                title: t('results_description_block_1_title'),
                backgroundColor: '#000000',
              },
              {
                description: t('results_description_block_2_description'),
                withPaddingBottom: true,
                backgroundColor: '#439000',
              },
            ]
          }
        />

        <MountainBreezeBackStage />
        <OtherCases />
      </MountainBreezeStyles>
    </CasesBaseStyles>
  );
}

export default MountainBreeze;
