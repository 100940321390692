import styled from 'styled-components';

export const ResultsStyles = styled.div`
  padding: 80px 0 0;
  
  @media (min-width: 768px) {
    padding: 100px 0 0;
  }
  
  .videosWrapper {
    .player-wrapper {
      border-radius: 10px;
      overflow: hidden;

      margin-bottom: 20px;
    }
    
    > .player-wrapper {
      margin-bottom: 20px;
    }
    
    .wrapper { 
      @media (min-width: 768px) {
        .player-wrapper {
          width: calc(50% - 10px);
          display: inline-block;

          &:nth-child(1) {
            margin-right: 20px;
          }
        }
      }
    }
  }
`;
