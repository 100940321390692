import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { BannerVideosStyles } from './BannerVideosStyles';

function BannerVideos() {
  return (
    <BannerVideosStyles>
      <div className="container">
        <div className="videosWrapper">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/shorts/06asHa3aEZk"
              width="100%"
              height="100%"
              controls
            />
          </div>

          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://youtu.be/5lroqVHJ2ZQ"
              width="100%"
              height="100%"
              controls
            />
          </div>

          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/shorts/YmcN-dXiubo"
              width="100%"
              height="100%"
              controls
            />
          </div>
        </div>
      </div>
    </BannerVideosStyles>
  );
}

export default BannerVideos;
