import React, { useCallback, useState } from 'react';
import { FormStyles } from './FormStyles';
import Header from '../../components/header/Header';
import Smile from '../../assets/icons/smile/Smile';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Spinner from '../../components/other/spinner/Spinner';
import { Step1 } from './steps/step1/Step1';
import { Step2 } from './steps/step2/Step2';
import { Step3 } from './steps/step3/Step3';
import { Step4 } from './steps/step4/Step4';
import { Step5 } from './steps/step5/Step5';
import { Step6 } from './steps/step6/Step6';

import emailjs from '@emailjs/browser';

export interface Values {
  what_need: string;
  terms: string;
  result: string;
  budget: string;
  activity: string;
  contact: string;
  phone: string;
}

function Form() {
  const { i18n, t } = useTranslation('form');

  const [values, setValues] = useState<Values>({
    what_need: '', terms: '', result: '', activity: '', budget: '', contact: '', phone: '',
  });
  const [activeStep, setActiveStep] = useState<number>(1);
  const [formSubmited, setFormSubmited] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const setFieldValue = useCallback((field: keyof Values, value: string) => {
    setValues({ ...values, [field]: value });
  }, [values]);

  async function handleSubmitForm() {
    try {
      setIsSubmitting(true);

      const data = {
        what_need: values.what_need,
        terms: values.terms,
        result: values.result,
        budget: values.budget,
        activity: values.activity,
        contact: values.contact,
        phone: values.phone,

        lang: i18n.language.toUpperCase(),
      };

      emailjs
        .send('service_2ups8ki', 'template_r1qqp2f', data, {
          publicKey: 'scfT6QB9SLCd-lmy0',
        })
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error: any) => {
            console.log('FAILED...', error.text);
          },
        );

      setFormSubmited(true);
    } catch (e) {
      console.error(`Error: ${e}`);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div>
      <Header greenBg={formSubmited} />
      <FormStyles className={classNames({ submited: formSubmited })}>
        {isSubmitting && <Spinner size={150} fullScreen />}

        {formSubmited && (
          <div className="container formSendedContainer">
            <h2 className="text100">
              {t('sent_title')}
            </h2>

            <div className="formSendedFooter">
              <Smile />

              <Link className="text90 linkToMainPage" to={`/${i18n.language}`}>
                {t('sent_go_to_main')}
              </Link>
            </div>
          </div>
        )}

        {!formSubmited && !isSubmitting && (
          <div className="container">
            <div className="titleWrapper">
              <h1 className="text100">{t('title')}</h1>

              {activeStep < 6 && (
                <span className="stepNumberText text100">
                  {activeStep}
                  /5
                </span>
              )}
            </div>

            {activeStep === 1 && (
              <Step1
                setFieldValue={setFieldValue}
                value={values.what_need}
                handleClickToNextButton={() => setActiveStep(2)}
              />
            )}
            {activeStep === 2 && (
              <Step2
                setFieldValue={setFieldValue}
                value={values.terms}
                handleClickToNextButton={() => setActiveStep(3)}
                handleClickToBackButton={() => setActiveStep(1)}
              />
            )}
            {activeStep === 3 && (
              <Step3
                setFieldValue={setFieldValue}
                value={values.result}
                handleClickToNextButton={() => setActiveStep(4)}
                handleClickToBackButton={() => setActiveStep(2)}
              />
            )}
            {activeStep === 4 && (
              <Step4
                setFieldValue={setFieldValue}
                value={values.budget}
                handleClickToNextButton={() => setActiveStep(5)}
                handleClickToBackButton={() => setActiveStep(3)}
              />
            )}
            {activeStep === 5 && (
              <Step5
                setFieldValue={setFieldValue}
                value={values.activity}
                handleClickToNextButton={() => setActiveStep(6)}
                handleClickToBackButton={() => setActiveStep(4)}
              />
            )}
            {activeStep === 6 && (
              <Step6
                setFieldValue={setFieldValue}
                contact={values.contact}
                phone={values.phone}
                handleClickToNextButton={handleSubmitForm}
                handleClickToBackButton={() => setActiveStep(5)}
              />
            )}
          </div>
        )}
      </FormStyles>
    </div>
  );
}

export default Form;
