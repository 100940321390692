import React from 'react';
import { ResultsStyles } from './ResultsStyles';
import ReactPlayer from 'react-player/lazy';
import showTextByLetter from '../../../../utils/ShowTextByLetter/ShowTextByLetter';
import { useTranslation } from 'react-i18next';

function Results() {
  const { t } = useTranslation('case_divergent');

  return (
    <ResultsStyles>
      <div className="container">
        <h2 className="h2">{showTextByLetter({ text: t('results_title'), delay: 40 })}</h2>

        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/X_u0rO-ab_g"
            width="100%"
            height="100%"
            controls
          />
        </div>
      </div>
    </ResultsStyles>
  );
}

export default Results;
