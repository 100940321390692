import React, { useMemo } from 'react';
import { ClientsStyles } from './ClientsStyles';

import bannerImage from '../../../assets/images/main/clients-banner.jpg';
import showTextByLetter from '../../../utils/ShowTextByLetter/ShowTextByLetter';
import { InView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

function Clients() {
  const { t, i18n } = useTranslation('main');

  return (
    <ClientsStyles>
      <div className="container">
        <div className="head">
          <h2 className="h2">{showTextByLetter({ text: t('clients_title'), delay: 20 })}</h2>

          <InView as="span" onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)} className="slogan">{t('clients_slogan')}</InView>
        </div>

        <div className="listBlockWrapper">
          <div className="listWrapper">
            <ul>{t('clients_list', { returnObjects: true }).map((client) => <InView as="li" onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)} key={client}>{showTextByLetter({ text: client, delay: 20 })}</InView>)}</ul>

            <span className="moreText mobileMoreText">{t('clients_more')}</span>
          </div>

          <div className="imageWrapper">
            <InView onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)} as="img" src={bannerImage} alt="Party" />

            <InView as="span" onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)} className="slogan">{t('clients_slogan')}</InView>
          </div>
        </div>

        <InView as="span" className="moreText desktopMoreText">{t('clients_more')}</InView>
      </div>
    </ClientsStyles>
  );
}

export default Clients;
