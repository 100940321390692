import React from 'react';
import { CasesBaseStyles } from '../CasesBaseStyles';
import Banner from '../../../components/cases/banner/Banner';
import BackStage from '../../../components/cases/backStage/BackStage';
import { MinistryOfVeteransAffairsStyles } from './MinistryOfVeteransAffairsStyles';
import OtherCases from '../../../components/cases/otherCases/OtherCases';
import { useTranslation } from 'react-i18next';
import Results from '../../../components/cases/ministryOfVeteransAffairs/results/Results';
import backstageImage1 from '../../../assets/images/ministryOfVeterans/backstage-1.png';
import backstageImage2 from '../../../assets/images/ministryOfVeterans/backstage-2.png';

function MinistryOfVeteransAffairs() {
  const { t } = useTranslation('case_ministry_of_veterans_affairs_of_ukraine');

  return (
    <CasesBaseStyles>
      <MinistryOfVeteransAffairsStyles>
        <Banner
          title={t('banner_title')}
          videoUrl="https://youtu.be/we5dYLdmtvI"
          descriptionBlock={
            {
              title: t('banner_description_block_title'),
              platformName: 'YouTube',
              description: t('banner_description_block_description'),
              category: [t('banner_description_block_category')],
              link: 'https://youtu.be/we5dYLdmtvI',
              clients: [t('banner_description_block_client_1')],
            }
          }
        />

        <BackStage
          title={t('backstage_title')}
          extraClasses="backstage"
          descriptionBlocks={
            [
              {
                description: t('backstage_description_block_1_description'),
                title: t('backstage_description_block_1_title'),
                backgroundColor: '#000000',
              },
              {
                description: t('backstage_description_block_2_description'),
                title: t('backstage_description_block_2_title'),
                backgroundColor: '#439000',
              },
            ]
          }
        >
          <img src={backstageImage1} alt="" />
          <img src={backstageImage2} alt="" />
        </BackStage>

        <Results />

        <OtherCases />
      </MinistryOfVeteransAffairsStyles>
    </CasesBaseStyles>
  );
}

export default MinistryOfVeteransAffairs;
