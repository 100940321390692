import React, { useMemo } from 'react';
import showTextByLetter from '../../../utils/ShowTextByLetter/ShowTextByLetter';

import instagramImage from '../../../assets/images/main/followUs/instagram.png';
import facebookImage from '../../../assets/images/main/followUs/facebook.png';
import youtubeImage from '../../../assets/images/main/followUs/youtube.png';
import vimeoImage from '../../../assets/images/main/followUs/vimeo.png';
import { Link } from 'react-router-dom';
import Circle from '../../other/circle/Circle';
import { FollowUsStyles } from './FollowUsStyles';
import { InView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

function FollowUs() {
  const { i18n, t } = useTranslation(['main', 'social_medias']);

  const contactUsListData = useMemo(() => [
    {
      name: t('social_medias:instagram'),
      path: process.env.REACT_APP_INSTAGRAM,
      image: instagramImage,
    },
    {
      name: t('social_medias:facebook'),
      path: process.env.REACT_APP_FACEBOOK,
      image: facebookImage,
    },
    {
      name: t('social_medias:youtube'),
      path: process.env.REACT_APP_YOUTUBE,
      image: youtubeImage,
    },
    {
      name: t('social_medias:telegram'),
      path: process.env.REACT_APP_TELEGRAM,
      image: vimeoImage,
    },
  ], [i18n.language]);

  return (
    <FollowUsStyles>
      <div className="container">
        <h2 className="h2">{showTextByLetter({ text: t('main:follow_us_title'), delay: 60 })}</h2>

        <div className="contactUsLinksWrapper">
          {contactUsListData.map((contactsItem) => (
            <InView as="a" onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)} key={contactsItem.name} href={contactsItem.path} target="_blank" rel="noreferrer">
              <div className="imageWrapper">
                <img src={contactsItem.image} alt={contactsItem.name} />
              </div>

              <div className="nameWrapper">
                <Circle />

                {contactsItem.name}
              </div>
            </InView>
          ))}
        </div>
      </div>
    </FollowUsStyles>
  );
}

export default FollowUs;
