import styled from 'styled-components';

export const BannerStyles = styled.div`
  .content {
    h1 {
      color: #000;

      font-size: 48px;
      font-weight: 900;
      
      @media (min-width: 768px) {
        font-size: 120px;
        letter-spacing: -4.8px;
      }
    }
    
    .bannerImageContainer {
      position: relative;
      
      margin-bottom: 40px;
      
      @media (min-width: 768px) {
        margin-bottom: 90px;
      }
      
      img {
        width: 100%;
      }
      
      .smile {
        display: flex;
        align-items: center;
        justify-content: center;
        
        position: absolute;

        bottom: 0;
        right: 16px;
        transform: translateY(50%) scale(1.2);
        
        width: 80px;
        height: 80px;
        border-radius: 100%;
        
        opacity: 0;
        
        background: #439000;
        transition: .7s ease opacity, .3s ease transform;
        
        &.inView {
          transform: translateY(50%) scale(1);
          opacity: 1;
        }
        
        svg {
          position: absolute;
          
          width: 90px;
          height: 90px;
        }

        @media (min-width: 768px) {
          width: 162px;
          height: 162px;

          top: 50%;
          right: 34px;
          bottom: auto;
          transform: translateY(-50%);

          svg {
            width: 180px;
            height: 180px;
          }
        }
      }
    }
    
    .textWrapper {
      display: flex;
      flex-direction: column;
      
      margin-bottom: 40px;
      
      opacity: 0;
      
      transition: 1s ease opacity;
      
      &.inView {
        opacity: 1;
      }
      
      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 102px;
      }
      
      h3 {
        color: #000;
        font-size: 24px;
        font-weight: 900;
        line-height: 27px;
        text-transform: uppercase;
        
        margin-bottom: 40px;
        
        @media (min-width: 768px) {
          margin-bottom: 0;
          max-width: 540px;
          
          font-size: 39px;
          line-height: 39px;
        }
      }
      
      .rightSide {
        display: flex;
        flex-direction: column;
        gap: 20px;
        
        @media (min-width: 768px) {
          max-width: 503px;
          justify-content: space-between;
        }
        
        h4 {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        
        a {
          display: flex;
          align-items: center;
          gap: 10px;

          color: #000;

          font-size: 18px;
          font-weight: 900;
          text-transform: uppercase;
          
          &:hover {
            .circle {
              background: #000;
            }
          }
        }
      }
    }
  }
`;
