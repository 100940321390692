import React from 'react';
import { CasesBaseStyles } from '../CasesBaseStyles';
import Banner from '../../../components/cases/banner/Banner';
import BackStage from '../../../components/cases/backStage/BackStage';
import { Divergent2Styles } from './Divergent-2-Styles';
import OtherCases from '../../../components/cases/otherCases/OtherCases';
import { useTranslation } from 'react-i18next';

import resultImage from '../../../assets/images/coffee/results-1.jpg';
import { useWindowSize } from 'usehooks-ts';

import bannerImage from '../../../assets/images/divergent/banner-1.png';

function Divergent2() {
  const { width } = useWindowSize();
  const { t } = useTranslation('case_divergent_2');

  return (
    <CasesBaseStyles>
      <Divergent2Styles>
        <Banner
          title={t('banner_title')}
          image={bannerImage}
          descriptionBlock={
            {
              title: t('banner_description_block_title'),
              platformName: 'YouTube',
              description: t('banner_description_block_description'),
              category: [t('banner_description_block_category')],
              link: 'https://youtu.be/s0GKu_ljTxs?si=H9NoQEn14oc02wJJ',
              clients: [],
            }
          }
        />

        <BackStage
          title={t('backstage_title')}
          descriptionBlocks={
            [
              {
                description: t('backstage_description'),
                backgroundColor: '#000000',
              },
              {
                description: t('backstage_list'),
                backgroundColor: '#000000',
              },
            ]
          }
        />

        <BackStage
          title={t('results_title')}
          titleColor="#000"
          titleWrapperBackgroundColor="#FFF"
          extraClasses="results"
          videoUrl="https://youtu.be/s0GKu_ljTxs?si=H9NoQEn14oc02wJJ"
          oneDescriotionBlock
          descriptionBlocks={
            [
              {
                description: t('results_description'),
                title: '',
                backgroundColor: '#439000',
              },
            ]
          }
        />

        <OtherCases />
      </Divergent2Styles>
    </CasesBaseStyles>
  );
}

export default Divergent2;
