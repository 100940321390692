import styled from 'styled-components';

export const ExpertiseStyles = styled.div`
  padding: 60px 0 40px;
  
  color: #FFF;
  background-color: #000;
  
  @media (min-width: 768px) {
    padding: 80px 0 60px;
  }
  
  .h2 {
    margin-bottom: 0;
  }
  
  .dot {
    width: 14px;
    height: 14px;
    
    border-radius: 100%;
    background-color: #FFF;
  }
  
  .titleWrapper, .bottomTextWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    
    .imageMobile {
      object-fit: cover;
      
      @media (min-width: 1024px) {
        display: none;
      }
    }
    
    .listWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      @media (min-width: 1024px) {
        align-items: flex-start;
        gap: 40px;
        
        padding-top: 70px;
      }
      
      .imageDesktop {
        display: none;
        
        @media (min-width: 1024px) {
          position: relative;
          
          display: block;
          padding-right: 30px;
          margin-top: -110px;
        }
      }
      
      ul {
        display: flex;
        flex-direction: column;
        gap: 12px;
        
        li {
          position: relative;
          
          opacity: 0;
          transform: translateY(10%);
          
          
          transition: .8s ease opacity, .5s ease transform;

          &.inView {
            opacity: 1;
            transform: translateY(0);
          }
          
          h4 {
            font-size: 29px;
            font-weight: 900;
            line-height: 39px;
            text-transform: uppercase;

            @media (min-width: 1024px) {
              font-size: 48px;
              line-height: 59px;
            }
          }
          
          &::after {
            content: "";
            position: absolute;
            bottom: -6px;
            left: 0;
            width: 100vw;
            transform: translateX(-20px);
            height: 1px;
            background: #FFFFFF33;
            
            @media (min-width: 1360px) {
              transform: translateX(calc(-1 * (-1360px - -100vw) / 2));
            }
          }
        }
      }
    }
    
    .bottomTextWrapper {
      a {
        color: #FFF;
        font-size: 34px;
        font-weight: 900;
        line-height: 42px;
        
        @media (min-width: 768px) {
          font-size: 90px;
          line-height: 110px;
          
          transition: .3s text-shadow;
          
          &:hover {
            text-shadow: 2px 3px 2px rgba(255, 115, 0, 0.8);
          }
        }
      }
    }
  }
`;
