import Button from '../../../../components/formPage/button/Button';
import React, { useMemo } from 'react';
import { Values } from '../../Form';
import { useTranslation } from 'react-i18next';

export const Step1 = ({ setFieldValue, value, handleClickToNextButton }: {
  setFieldValue: (field: keyof Values, value: string) => void,
  value: string,
  handleClickToNextButton: () => void
}) => {
  const { t, i18n } = useTranslation('form');

  const whatWeNeedOptions = useMemo(() => t('step_1_options', { returnObjects: true }), [i18n.language]);

  return (
    (
      <div className="step">
        <div className="stepHead">
          <h2>{t('step_1_title')}</h2>

          <div className="optionsButtonsWrapper">
            {whatWeNeedOptions.map((option) => (
              <Button text={option.text} selected={value === option.value} onClick={() => setFieldValue('what_need', option.value)} />
            ))}
          </div>
        </div>

        <div className="stepFooter">
          <div />

          <button disabled={value === ''} type="button" className="nextButton text90" onClick={handleClickToNextButton}>
            {t('next')}
          </button>
        </div>
      </div>
    )
  );
};
