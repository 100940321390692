import styled from 'styled-components';

export const UNFPA5Styles = styled.div`
  .banner {
  }
  
  .results {
    .descriptionBlockDescription {
      max-width: 820px;
    }

    .mediaImage {
      padding-bottom: 20px;
    }
  }
`;
