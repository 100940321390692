import React from 'react';
import { CasesBaseStyles } from '../CasesBaseStyles';
import Banner from '../../../components/cases/banner/Banner';
import BackStage from '../../../components/cases/backStage/BackStage';

import backstageImage from '../../../assets/images/ufnap5/backstage-1.png';
import { UNFPA5Styles } from './UNFPA5Styles';
import Results from '../../../components/cases/ufnpa3/results/Results';
import OtherCases from '../../../components/cases/otherCases/OtherCases';
import { useTranslation } from 'react-i18next';

import resultImage from '../../../assets/images/ufnap5/results-1.png';
import bannerImage from '../../../assets/images/ufnap5/banner.png';

function UNFPA5() {
  const { t } = useTranslation('case_unfpa_5');

  return (
    <CasesBaseStyles>
      <UNFPA5Styles>
        <Banner
          title={t('banner_title')}
          image={bannerImage}
          descriptionBlock={
            {
              title: t('banner_description_block_title'),
              description: t('banner_description_block_description'),
              category: [t('banner_description_block_category')],
              clients: [t('banner_description_block_client_1')],
            }
          }
        />

        <BackStage
          title={t('backstage_title')}
          image={backstageImage}
          descriptionBlocks={
            [
              {
                description: t('backstage_description_block_1_description'),
                title: t('backstage_description_block_1_title'),
                backgroundColor: '#000000',
              },
              {
                description: t('backstage_description_block_2_description'),
                title: t('backstage_description_block_2_title'),
                backgroundColor: '#439000',
              },
            ]
          }
        />

        <BackStage
          title={t('results_title')}
          titleColor="#000"
          titleWrapperBackgroundColor="#FFF"
          extraClasses="results"
          image={resultImage}
          oneDescriotionBlock
          descriptionBlocks={
            [
              {
                description: t('results_description'),
                title: '',
                backgroundColor: '#439000',
              },
            ]
          }
        />

        <OtherCases />
      </UNFPA5Styles>
    </CasesBaseStyles>
  );
}

export default UNFPA5;
