import styled from 'styled-components';

export const PolicyStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  
  padding-top: 40px;
  padding-bottom: 40px;
  
  color: #000;
  
  a {
    text-decoration: underline;
    color: #000;
  }
  
  h1, h2 {
    text-align: center;
  }
  
  .centered {
    text-align: center;
  }
  
  .block {
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    ul {
      list-style-type: initial;
      
      padding-left: 38px;
    }
    
    ol, ul {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    
    ol {
      .item-2-11 {
        display: flex;
        flex-direction: column;
        
        .head {
          display: flex;
          gap: 8px;
        }
        
        .linksWrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;
          
          padding-left: 60px;
        }
      }
      
      li {
        display: grid;
        grid-template-columns: 30px 1fr;
        
        gap: 8px;
        
        list-style-type: none;
      }
    }
  }
`;
