import React from 'react';
import { GoToFormSectionStyles } from './GoToFormSectionStyles';
import Smile from '../../assets/icons/smile/Smile';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

function GoToFormSection() {
  const { i18n, t } = useTranslation('layout');

  return (
    <GoToFormSectionStyles>
      <div className="container">
        <InView as="h2" onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : null)}>{t('go_to_form_title')}</InView>

        <InView onChange={(inView, entry) => (inView ? entry.target.classList.add('inView') : entry.target.classList.remove('inView'))} className="sectionFooter">
          <Smile />

          <Link to={`/${i18n.language}/form`}>{t('go_to_form_link_text')}</Link>
        </InView>
      </div>
    </GoToFormSectionStyles>
  );
}

export default GoToFormSection;
