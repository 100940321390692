import styled from 'styled-components';

export const ZnayStyles = styled.div`
  .banner {

  }
  
  .results {
    .descriptionBlockDescription {
      max-width: 820px;
    }
  }
`;
