import Input from '../../../../components/formPage/input/Input';
import React from 'react';
import { Values } from '../../Form';
import { useTranslation } from 'react-i18next';

export const Step3 = ({
  setFieldValue, value, handleClickToNextButton, handleClickToBackButton,
}: {
  setFieldValue: (field: keyof Values, value: string) => void,
  value: string,
  handleClickToNextButton: () => void
  handleClickToBackButton: () => void
}) => {
  const { t } = useTranslation('form');

  return (
    <div className="step">
      <div className="stepHead">
        <h2>{t('step_3_title')}</h2>

        <div className="inputsWrapper">
          <Input
            filled={value.length > 0}
            value={value}
            setValue={(enteredValue) => setFieldValue('result', enteredValue)}
            placeholder={t('placeholder_write_here')}
          />
        </div>
      </div>

      <div className="stepFooter">
        <button type="button" className="backButton" onClick={handleClickToBackButton}>{t('back')}</button>

        <button disabled={value === ''} type="button" className="nextButton text90" onClick={handleClickToNextButton}>
          {t('next')}
        </button>
      </div>
    </div>
  );
};
