import styled from 'styled-components';

export const ResultsStyles = styled.div`
  padding: 80px 0 0;
  
  @media (min-width: 768px) {
    padding: 100px 0 0;
  }
  
  .imagesWrapper {
    display: flex;

    gap: 20px;
    
    padding: 0 20px 20px 20px;
    
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 9px;
      
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        width: 100%;
      }
      
      @media (min-width: 768px) {
        &:nth-child(1) {
          display: block;
          width: calc(66% - 10px);
        }

        &:nth-child(2) {
          width: calc(34% - 10px);
        }
      }
      
      @media (min-width: 1024px) {
        &:nth-child(1) {
          
        }

        &:nth-child(2) {

        }
      }
      
      @media (min-width: 1240px) {
        &:nth-child(1) {

        }

        &:nth-child(2) {

        }
      }
    }
  }
`;
