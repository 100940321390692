import Input from '../../../../components/formPage/input/Input';
import React from 'react';
import { Values } from '../../Form';
import { useTranslation } from 'react-i18next';

const phoneRegExp = /^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$/;

export const Step6 = ({
  setFieldValue, contact, phone, handleClickToNextButton, handleClickToBackButton,
}: {
  setFieldValue: (field: keyof Values, value: string) => void,
  contact: string,
  phone: string,
  handleClickToNextButton: () => void
  handleClickToBackButton: () => void
}) => {
  const { t } = useTranslation('form');

  return (
    <div className="step">
      <div className="stepHead">
        <h2>{t('step_6_title')}</h2>

        <div className="inputsWrapper">
          <Input filled={contact.length > 0} value={contact} setValue={(enteredValue) => setFieldValue('contact', enteredValue)} placeholder={t('step_6_contacts_placeholder')} />
          <Input
            filled={phone.length > 0 && phoneRegExp.test(phone)}
            error={phone.length > 0 && !phoneRegExp.test(phone)}
            value={phone}
            setValue={(enteredValue) => setFieldValue('phone', enteredValue)}
            placeholder={t('step_6_phone_placeholder')}
          />
        </div>
      </div>

      <div className="stepFooter">
        <button type="button" className="backButton" onClick={handleClickToBackButton}>{t('back')}</button>

        <button disabled={contact.length === 0 || !phoneRegExp.test(phone)} type="button" className="nextButton text90" onClick={handleClickToNextButton}>
          {t('send')}
        </button>
      </div>
    </div>
  );
};
