import styled from 'styled-components';

export const InputStyles = styled.input`
  outline: none;
  background-color: inherit;
  
  width: 100%;
  border: none;
  border-bottom: 2px solid #000;

  font-size: 18px;
  font-weight: 900;
  line-height: 22px;
  transition: .3s ease border-bottom-color;
  
  @media (min-width: 768px) {
    font-family: Helvetica Neue, sans-serif;
    font-size: 28px;
    line-height: 34px;
  } 
  
  &.error {
    border-bottom: 2px solid red;
  }
  
  &.filled {
    border-bottom: 2px solid #439000;
  }
  
  &::placeholder {
    font-size: 18px;
    font-weight: 900;
    line-height: 22px;
    color: #000;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-family: Helvetica Neue, sans-serif;
      font-size: 28px;
      line-height: 34px;
    }
  }
`;
