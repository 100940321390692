import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';

import * as en from './translations/en.json';
import * as uk from './translations/uk.json';

export const defaultNS = 'translations';

export const resources = {
  en,
  uk,
};

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof defaultNS;
    resources: typeof resources['en'];
  }
}

const cookieDomain = process.env.REACT_APP_BASE_URL;

const languageDetectorOptions: DetectorOptions = {
  order: ['path', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'subdomain'],
  lookupFromPathIndex: 0,
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain,

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' },

  // optional conversion function to use to modify the detected language code
  // convertDetectedLanguage: 'Iso15897',
  convertDetectedLanguage: (lng: string) => lng.split('-')[0],
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: languageDetectorOptions,
    resources,
    load: 'languageOnly',
    fallbackLng: 'en',
    defaultNS,
    supportedLngs: ['uk', 'en'],
    ns: ['pricing', 'privacy_policy', 'terms', 'translations', 'other'],
    nonExplicitSupportedLngs: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
